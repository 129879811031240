import { StepLabel, withStyles } from '@material-ui/core'

const opacity = '1 !important'

export const POSStepLabel = withStyles((theme) => ({
    completed: {
        opacity,
    },
    active: {
        opacity,
    },
    label: {
        color: `${theme.palette.primary.main} !important`,
        fontWeight: 500,
        opacity: 0.24,
    },
}))(StepLabel)
