import { useQuery, UseQueryOptions } from 'react-query'

import { posCheckoutBffClient } from 'src/config/data-access/axios'

import { ICoupon } from 'src/types'

type CheckCouponParams = {
    courseId: number
    coupon?: string
}

export const useCheckCoupon = (
    { courseId, coupon }: CheckCouponParams,
    options?: UseQueryOptions<any, any, ICoupon, any>
) => {
    const path = `v1/discounts/check?promoCode=${coupon}&courseId=${courseId}`

    return useQuery<ICoupon>(
        path,
        () => {
            return posCheckoutBffClient.get(path).then(({ data }) => data)
        },
        options
    )
}
