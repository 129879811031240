import { Typography, Box, useMediaQuery, useTheme } from '@material-ui/core'

import { ReactComponent as LogoSymbol } from 'src/assets/images/logo-symbol.svg'

import { POSStepper } from 'src/components'

import { testID } from 'src/config/test'

type Size = 'default' | 'small'

type POSHeaderStepperProps = {
    activeStep?: number
    courseName: string
    size?: Size
}

const courseNameVariant = (size: Size, matches: boolean) => {
    if (size === 'default') {
        return matches ? 'h4' : 'h3'
    }

    return matches ? 'h6' : 'h5'
}

export const POSHeaderStepper = ({
    activeStep,
    courseName,
    size = 'default',
}: POSHeaderStepperProps) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <>
            <Box display="inline-flex" alignItems="center">
                <LogoSymbol width={24} />
                <Box mr={1} />
                <Typography
                    color="secondary"
                    variant="body2"
                    style={{ fontWeight: 700 }}
                >
                    Pós-graduação Online
                </Typography>
            </Box>
            <Typography
                color="primary"
                variant={courseNameVariant(size, matches)}
                style={{ fontWeight: 700 }}
                {...testID('course-name')}
            >
                {courseName}
            </Typography>

            {typeof activeStep === 'number' && (
                <Box my={4}>
                    <POSStepper activeStep={activeStep} />
                </Box>
            )}
        </>
    )
}
