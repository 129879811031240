import { withStyles, StepConnector } from '@material-ui/core'

export const POSStepperConnector = withStyles((theme) => ({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    disabled: {
        '& $line': {
            borderColor: theme.palette.primary.main,
            opacity: 0.24,
        },
    },
    line: {
        borderTopWidth: 4,
    },
}))(StepConnector)
