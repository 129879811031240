const InfoHeader: React.FC<{ index: number; testId?: string }> = ({
    index,
    children,
    testId,
}) => {
    return (
        <div
            data-testid={testId}
            style={{
                backgroundColor: '#EDF2FD',
                padding: '1rem',
                display: 'flex',
                alignContent: 'center',
                gap: '1.5rem',
                borderRadius: '0.5rem',
            }}
        >
            <span
                style={{
                    borderRadius: '50%',
                    width: '32px',
                    height: '32px',
                    backgroundColor: '#002266',
                    color: 'white',
                    fontWeight: 700,
                    fontSize: '1.125rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                {index}
            </span>
            {children}
        </div>
    )
}

export default InfoHeader
