import { FieldConfig, GenericFieldHTMLAttributes, Field } from 'formik'
import { TextFieldProps, InputLabel, InputAdornment } from '@material-ui/core'
import { CountryProperty, customList } from 'country-codes-list'

import { POSTextMask } from 'src/components'
import { INPUT_COUNTRY_CODE_MASK } from 'src/utils/masks/'
import { testID } from 'src/config/test'

import '/node_modules/flag-icons/css/flag-icons.min.css'

export type POSFieldCountryCodeProps = GenericFieldHTMLAttributes &
    FieldConfig &
    TextFieldProps

const getCountryCodeByDdi = (countryCallingCode: string) => {
    if (!countryCallingCode) return ''

    const countries: { [key: string]: string } = customList(
        'countryCode' as CountryProperty,
        '+{countryCallingCode}'
    )

    const countryCode = Object.keys(countries ?? {}).find(
        (country) => countries[country] === countryCallingCode.replace('-', ' ')
    )

    return countryCode?.toLowerCase()
}

export const POSFieldCountryCode = ({
    label,
    ...props
}: POSFieldCountryCodeProps) => {
    const countryCode = getCountryCodeByDdi(props.value as string)

    return (
        <>
            <InputLabel>{label}</InputLabel>
            <Field
                component={POSTextMask}
                mask={INPUT_COUNTRY_CODE_MASK}
                variant="filled"
                fullWidth
                {...props}
                inputProps={{
                    ...props?.inputProps,
                }}
                startAdornment={
                    <InputAdornment style={{ marginTop: 0 }} position="start">
                        <span
                            className={`fi fi-${countryCode}`}
                            {...testID('flag')}
                        />
                    </InputAdornment>
                }
            />
        </>
    )
}
