import { useCallback, useEffect, useState } from 'react'

import { Box, Grid, useMediaQuery } from '@material-ui/core'

import { POSHeaderStepper, POSVerifyRouteAccess } from 'src/components'

import {
    useMainContext,
    useCheckCoupon,
    useTrackerContext,
    useScrollToElement,
} from 'src/hooks'

import { Dictionary, PaymentMethodType } from 'src/types'

import { Plans } from './Plans'
import { CreditCard, SubmitCreditCard } from './CreditCard'
import { BankSlip, SubmitBankSlip } from './BankSlip'
import { PosModal } from './components/Modal'
import { theme } from 'src/theme'

const translateCouponErrorCode: Dictionary = {
    COUPON_NOT_FOUND: 'Cupom inválido.',
    COUPON_EXPIRED: 'Cupom expirado.',
    COUPON_NOT_ACCEPTED_TO_COURSE: 'Cupom inválido para este curso.',
    COUPON_NOT_STARTED: 'Não iniciamos essa promoção ainda.',
    COUPON_MAX_USAGE: 'Limite de cupons excedido.',
}

export const getCouponOptions = (
    isLoading: boolean,
    isError: boolean,
    isSuccess: boolean,
    percentage?: number,
    error?: any
) => {
    if (isLoading) {
        return {
            status: '',
            text: '',
        }
    } else if (!isLoading && isError) {
        return {
            status: 'error',
            text:
                translateCouponErrorCode[error?.response?.data?.code] ||
                translateCouponErrorCode.COUPON_NOT_FOUND,
        }
    } else if (!isLoading && isSuccess) {
        return {
            status: 'success',
            text: `Cupom válido. ${percentage}% de desconto aplicado!`,
        }
    }
}

const Payment = () => {
    const [modal, setModal] = useState(false)

    const openModal = () => {
        setModal(true)
    }

    const closeModal = () => {
        setModal(false)
    }

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const {
        data,
        buyer,
        setBuyer,
        productSelectValue,
        setProductSelectValue,
        productSelected,
        paymentMethodSelectValue,
        setCourse,
        goToBasicData,
        goToReview,
        isRecurrenceEnabled,
        isPromoEnabled,
        isCouponEnabled,
        isBankSlipEnabled,
        comparePricesFlag,
        comboVariant,
    } = useMainContext()

    const { track } = useTrackerContext()
    const { goScrollTo } = useScrollToElement()

    const {
        isLoading,
        isError,
        isSuccess,
        error,
        data: dataCoupon,
    } = useCheckCoupon(
        {
            coupon: buyer?.promoCode,
            courseId: data?.id!,
        },
        {
            retry: false,
            enabled: !!buyer?.promoCode && !!data?.id,
            staleTime: 0,
            onSuccess: (coupon) => {
                setCourse((prev) => ({
                    ...prev,
                    coupon,
                }))

                track({
                    eventName: 'Coupon applied',
                    additionalAttrs: {
                        'Coupon code': coupon?.promoCode,
                        'Course name': data?.name,
                        'Course duration': `${data?.duration} meses`,
                    },
                })
            },
        }
    )

    useEffect(() => {
        if (!!data?.id) {
            track({
                eventName: 'Payment method page viewed',
                additionalAttrs: {
                    'Course name': data?.name,
                    'Course duration': `${data?.duration} meses`,
                },
            })
        }
    }, [track, data?.name, data?.id, data?.duration])

    const handleChangePlan = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setProductSelectValue(event.target.value)
            goScrollTo('coupon')
        },
        [setProductSelectValue, goScrollTo]
    )

    const handleApplyCoupon = useCallback(
        (coupon: string) => {
            setBuyer((prev) => ({ ...prev, promoCode: coupon }))
            goScrollTo('coupon')
        },
        [setBuyer, goScrollTo]
    )

    const handleTrackPayment = () => {
        track({
            eventName: 'Payment method chosen',
            additionalAttrs: {
                'Payment method': productSelected?.customDescription,
                'Course name': data?.name,
                'Course duration': `${data?.duration} meses`,
            },
        })
    }

    const handleSubmitCreditCard: SubmitCreditCard = (values) => {
        setBuyer((prev) => ({
            ...prev,
            paymentMethod: PaymentMethodType.CREDIT_CARD,
            recaptcha: values?.recaptcha,
            creditCard: values,
            cpf: values?.cpf,
            billingDay: values?.billingDay,
        }))

        handleTrackPayment()

        goToReview()
    }

    const handleSubmitBankSlip: SubmitBankSlip = (values) => {
        setBuyer((prev) => ({
            ...prev,
            ...values,
            paymentMethod: PaymentMethodType.BANK_SLIP,
            promoCode: buyer?.promoCode,
        }))

        handleTrackPayment()

        goToReview()
    }

    const isBankSlipSelected =
        paymentMethodSelectValue === PaymentMethodType.BANK_SLIP

    const couponOptions = getCouponOptions(
        isLoading,
        isError,
        isSuccess,
        dataCoupon?.percentage,
        error
    )

    return (
        <Box mb={7}>
            <POSVerifyRouteAccess
                condition={
                    !buyer?.address ||
                    !buyer.name ||
                    !buyer.email ||
                    !buyer.phone
                }
            />

            <POSHeaderStepper courseName={data?.name || ''} activeStep={1} />

            {modal && (
                <PosModal
                    open={modal}
                    onClose={closeModal}
                    isMobile={isMobile}
                    hasBankSlip={!isRecurrenceEnabled && isBankSlipEnabled}
                    data={data}
                    isPromoEnabled={isPromoEnabled}
                    isRecurrenceEnabled={isRecurrenceEnabled || false}
                />
            )}

            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Plans
                        productSelected={productSelected}
                        setProductSelectValue={setProductSelectValue}
                        hasBankSlip={!isRecurrenceEnabled && isBankSlipEnabled}
                        installments={productSelected?.installments}
                        products={data?.products}
                        handleChangePlan={handleChangePlan}
                        handleApplyCoupon={handleApplyCoupon}
                        paymentMethodSelectValue={paymentMethodSelectValue}
                        defaultCouponValue={buyer?.promoCode}
                        isLoadingCoupon={isLoading}
                        status={couponOptions?.status as any}
                        helperTextCoupon={couponOptions?.text}
                        isRecurrenceEnabled={isRecurrenceEnabled || false}
                        priceFirstInstallment={
                            productSelected?.priceFirstInstallment
                        }
                        openModal={openModal}
                        couponData={dataCoupon}
                        isPromoEnabled={isPromoEnabled}
                        isCouponEnabled={isCouponEnabled}
                        comparePricesFlag={comparePricesFlag}
                        comboVariant={comboVariant}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={8}>
                    {isBankSlipSelected ? (
                        <BankSlip
                            disabled={!productSelectValue}
                            onSubmit={handleSubmitBankSlip}
                            onBack={goToBasicData}
                            initialValues={buyer || {}}
                            availableBillingDays={
                                productSelected?.availableBillingDays
                            }
                        />
                    ) : (
                        <CreditCard
                            disabled={!productSelectValue}
                            availableBillingDays={
                                productSelected?.availableBillingDays
                            }
                            onSubmit={handleSubmitCreditCard}
                            onBack={goToBasicData}
                            initialValues={buyer?.creditCard || {}}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}

export default Payment
