import { useEffect } from 'react'

import { env } from 'src/config/enviroments'

const loadScript = (src: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = src
        script.type = 'text/javascript'

        script.onload = () => {
            resolve(script)
        }

        script.onerror = () => {
            reject(new Error('Failed to load new relic'))
        }

        document.head.appendChild(script)
    })
}

const { PUBLIC_URL } = process.env

export const useLoadNewRelic = () => {
    useEffect(() => {
        loadScript(`${PUBLIC_URL}/newrelic/${env.ENVIRONMENT}.js`)
    }, [])
}
