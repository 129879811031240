import React from "react";

import { CssBaseline, ThemeProvider } from "@material-ui/core";

import { theme } from "src/theme";

export const MaterialUIProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
