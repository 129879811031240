import { FlagProvider } from '@unleash/proxy-client-react'
import React from 'react'

const config = {
    url: window.env.UNLEASH_URL,
    clientKey: window.env.UNLEASH_SECRET,
    appName: 'default',
    environment: 'default',
    refreshInterval: 15,
}

export const UnleashProvider: React.FC = ({ children }) => (
    <FlagProvider config={config}>{children}</FlagProvider>
)
