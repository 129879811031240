import { FieldConfig, GenericFieldHTMLAttributes, Field } from 'formik'
import { TextFieldProps, InputLabel } from '@material-ui/core'

import { INPUT_CEP_MASK } from 'src/utils/masks'

import { POSTextMask } from 'src/components'

export type POSFieldCepProps = GenericFieldHTMLAttributes &
    FieldConfig &
    TextFieldProps

export const POSFieldCep = ({ label, ...props }: POSFieldCepProps) => (
    <>
        <InputLabel>{label}</InputLabel>
        <Field
            component={POSTextMask}
            mask={INPUT_CEP_MASK}
            variant="filled"
            fullWidth
            inputProps={{
                inputMode: 'numeric',
            }}
            {...props}
        />
    </>
)
