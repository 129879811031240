import { HubspotFormLegalConsent } from '@sanardigital/hubspot-client/dist/main/interfaces'

export const getCampaignFields = (query: URLSearchParams) => [
    { name: 'utm_source', value: query.get('utm_source') || '' },
    { name: 'utm_term', value: query.get('utm_term') || '' },
    { name: 'utm_campaign', value: query.get('utm_campaign') || '' },
    { name: 'utm_medium', value: query.get('utm_medium') || '' },
    { name: 'utm_content', value: query.get('utm_content') || '' },
]

export const legalConsentOptions: HubspotFormLegalConsent = {
    legitimateInterest: {
        value: true,
        subscriptionTypeId: 19440592,
        legalBasis: 'LEAD',
        text: 'Utilizamos seus dados para analisar e personalizar nossos conteúdos e anúncios durante a sua navegação em nossa plataforma, em serviços de terceiros parceiros e para comunicar serviços e produtos. Ao navegar pelo site, você nos autoriza coletar tais informações e utilizá-las para estas finalidades. Em caso de dúvidas, acesse nossa Política de Privacidade.',
    },
}

export const transformCourseName = (className: string): string => {
    switch (className) {
        case 'Medicina do Exercício e do Esporte':
            return 'Medicina do exercício e do esporte'
        case 'MFC':
            return 'Medicina de família e comunidade'
        case 'Medicina Paliativa e Cuidados Paliativos':
            return 'Cuidados paliativos'
        case 'Terapia Intensiva':
            return 'Terapia intensiva'
        case 'Medicina do Trabalho':
            return 'Medicina do trabalho'
        default:
            return className
    }
}
