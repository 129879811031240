import { Fragment } from 'react'

import { Box, Typography, BoxProps } from '@material-ui/core'

import { testID } from 'src/config/test'

type Info = {
    label: string
    value?: string
}

type BoxInfoProps = {
    title: string
    infos: Info[]
    children?: React.ReactNode
} & BoxProps

export const BoxInfo = ({ infos, title, children, ...props }: BoxInfoProps) => (
    <Box
        display="flex"
        flexDirection="column"
        flex={1}
        pt={{ xs: 0, md: 3 }}
        {...props}
    >
        <Typography
            gutterBottom
            color="primary"
            style={{ fontWeight: 700, fontSize: '0.625rem' }}
        >
            {title}
        </Typography>

        {children}

        {infos.map(({ label, value }) => {
            const valueLabel = !!value ? `: ${value}` : ''
            return (
                <Fragment key={label}>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                        gutterBottom
                        {...testID('info')}
                    >
                        {`${label}${valueLabel}`}
                    </Typography>
                </Fragment>
            )
        })}
    </Box>
)
