import { Box, Typography } from '@material-ui/core'
import { ReactComponent as CreditCardIcon } from 'src/assets/images/credit-card.svg'

const Tooltip: React.FC<{ text: string }> = ({ text }) => {
    return (
        <Box
            height={'2.25rem'}
            alignItems={'center'}
            display={'flex'}
            padding={'0.5rem 1rem'}
            style={{ backgroundColor: '#002266' }}
            borderRadius={'0.5rem'}
            position={'relative'}
            width={'9rem'}
            marginBottom={'1rem'}
            alignSelf={'flex-start'}
            gridGap={'0.5rem'}
        >
            <>
                <CreditCardIcon
                    color="rgba(255, 255, 255, 0.85)"
                    height={'1.5rem'}
                />
                <Typography
                    style={{
                        color: 'rgba(255, 255, 255, 0.85)',
                        fontWeight: 700,
                        fontSize: '0.75rem',
                    }}
                >
                    {text} 
                </Typography>
            </>
            <div
                style={{
                    width: 0,
                    height: 0,
                    borderLeft: '5px solid transparent',
                    borderRight: '5px solid transparent',
                    borderTop: '10px solid rgb(0, 34, 102)',
                    fontSize: 0,
                    lineHeight: 0,
                    float: 'left',
                    position: 'absolute',
                    left: '1rem',
                    bottom: '-0.5rem',
                }}
            />
        </Box>
    )
}

export default Tooltip
