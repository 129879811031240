import React, { createContext, useCallback } from 'react'

import { env } from 'src/config/enviroments'

import {
    IdentifyParams,
    TrackerParams,
    Tracker,
} from 'src/config/trackers/types'

interface ITrackerContext {
    track: (params: TrackerParams) => void
    identify: (params: IdentifyParams) => void
}

type MetricsProps = {
    trackers: Tracker[]
}

export const TrackerContext = createContext<ITrackerContext>(
    {} as ITrackerContext
)

export const TrackerProvider: React.FC<MetricsProps> = ({
    children,
    trackers,
}) => {
    const track = useCallback(
        (params: TrackerParams) => {
            const { eventName, additionalAttrs } = params
            trackers.forEach((tracker) => {
                tracker.track?.({
                    eventName,
                    additionalAttrs: {
                        ...additionalAttrs,
                        environment: env.ENVIRONMENT,
                    },
                })
            })
        },
        [trackers]
    )

    const identify = useCallback(
        (user: IdentifyParams) => {
            trackers.forEach((tracker) => {
                tracker.identify(user)
            })
        },
        [trackers]
    )

    return (
        <TrackerContext.Provider value={{ track, identify }}>
            {children}
        </TrackerContext.Provider>
    )
}
