import { IComboVariant } from '../types'

interface IComboHelper {
    comboCourses: string[] | undefined
    uniqueCourses: string[]
}

export default function comboHelper(
    comboVariant: IComboVariant[] | undefined
): IComboHelper {
    const comboCourses = comboVariant?.flatMap((course) => [
        course.current.slug,
        course.next.slug,
    ])

    const uniqueCourses = Array.from(new Set(comboCourses))

    return {
        comboCourses,
        uniqueCourses,
    }
}
