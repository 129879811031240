import { makeStyles, Grid, MenuItem, Tooltip } from '@material-ui/core'
import { Help } from '@material-ui/icons'
import { POSFieldFormik } from '../../../components'
import { testID } from '../../../config/test'

type Props = {
    availableBillingDays: number[]
    initialValue?: string
    disabled?: boolean
}

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        maxHeight: 250,
    },
    tooltip: {
        position: 'relative',
        top: '-78px',
        right: '-170px',
        [theme.breakpoints.down('xs')]: {
            top: '-80px',
            right: '-175px'
        },
    },
    billingDayContainer: {
        display: 'flex',
        alignSelf: 'center',
         '&.MuiGrid-item': {
            padding: '0 0.875rem', 
         },
         [theme.breakpoints.down('xs')]: {
            position: 'relative',
            top: '-30px'
        },
    },
    billingDayInfo: {
        backgroundColor: '#F7F8FA',
        padding: '0.5rem 0.25rem',
        fontSize: '0.6rem',
    }
}))

const BillingDayContainer = ({
    availableBillingDays,
    initialValue,
    disabled
}: Props) => {
    const classes = useStyles()

  return (
    <>
    <Grid item xs={12} sm={3}>
    <POSFieldFormik
            select
            required
            name="billingDay"
            label="Dia para próximas cobranças: "
            disabled={disabled}
            placeholder="Selecione"
            defaultValue={initialValue}
            SelectProps={{
                MenuProps: {
                    classes: {
                        paper: classes.menuPaper,
                    },
                },
                ...testID('billing-day'),
            }}
        >
            {availableBillingDays.map((value) => (
                <MenuItem value={value} key={`billing-day-${value}`}>
                    {value}
                </MenuItem>
            ))}
        </POSFieldFormik>
        <Tooltip 
            enterTouchDelay={0} 
            className={classes.tooltip} 
            placement='top-start' 
            title='Selecione o melhor dia para recorrência dos próximos pagamentos'>
                <Help color='primary' fontSize='small' />
        </Tooltip>
    </Grid>
    <Grid item xs={12} sm={3} className={classes.billingDayContainer}>
        <span className={classes.billingDayInfo}>A primeira parcela é cobrada no ato. As demais no dia selecionado do próximo período (mês, trimestre)</span>
    </Grid>
    </>
  )
}

export default BillingDayContainer