import {
    Box,
    Button,
    Grid,
    Link,
    makeStyles,
    Typography,
    MenuItem,
} from '@material-ui/core'
import { Form, Formik, FormikHelpers } from 'formik'
import { POSCard, POSFieldFormik } from 'src/components'
import { testID } from 'src/config/test'
import { statesList } from 'src/utils'
import { genderList } from 'src/utils/gender-list'
import * as Yup from 'yup'

const initialValues = {
    zipCode: '',
    street: '',
    number: '',
    neighborhood: '',
    complement: '',
    city: '',
    state: '',
    rg: '',
    birthdate: new Date(),
    gender: '',
}

export type AdditionalInformationFormValues = Partial<typeof initialValues>

export type SubmitAdditionalInfo = (
    values: AdditionalInformationFormValues,
    formik: FormikHelpers<AdditionalInformationFormValues>
) => void

type AdditionalInformationProps = {
    disabled?: boolean
    onSubmit: SubmitAdditionalInfo
    onBack: () => void
    initialValues: AdditionalInformationFormValues
}

const validationSchema = Yup.object().shape({
    zipCode: Yup.string()
        .required('CEP é obrigatório')
        .min(10, 'Deve ter 8 dígitos')
        .max(10, 'Deve ter 8 dígitos'),
    street: Yup.string().required('Endereço é obrigatório'),
    number: Yup.string().required('Número é obrigatório'),
    neighborhood: Yup.string().required('Bairro é obrigatório'),
    complement: Yup.string(),
    city: Yup.string().required('Cidade é obrigatório'),
    state: Yup.string().required('Estado é obrigatório'),
    rg: Yup.string().optional(),
    birthdate: Yup.date().required('Data de nascimento é obrigatório'),
    gender: Yup.string().required('Sexo é obrigatório'),
})

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        maxHeight: 250,
    },
}))

const text = 'Informe os dados a seguir:'

export const AdditionalInfo = ({
    disabled,
    onSubmit,
    onBack,
    initialValues: initialValuesProp,
}: AdditionalInformationProps) => {
    const classes = useStyles()
    const opacityProp = disabled ? { opacity: '0.2' } : {}

    return (
        <POSCard
            p={2}
            wrapperProps={{ position: 'relative', style: { ...opacityProp } }}
        >
            <Box>
                <Typography> {text} </Typography>
            </Box>
            <Box mb={4} />
            <Formik
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                initialValues={
                    { ...initialValues, ...initialValuesProp } as any
                }
            >
                {({ isSubmitting, resetForm }) => (
                    <Form>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <POSFieldFormik
                                    required
                                    name="zipCode"
                                    label="CEP:"
                                    placeholder="00.000-000"
                                    disabled={disabled}
                                    fieldType="cep"
                                    inputProps={{
                                        ...testID('zipCode'),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <POSFieldFormik
                                    required
                                    name="street"
                                    label="Endereço:"
                                    placeholder="Rua/Avenida/Praça/Outros"
                                    disabled={disabled}
                                    inputProps={{
                                        ...testID('street'),
                                        maxLength: 200,
                                        minLength: 2,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <POSFieldFormik
                                    required
                                    name="number"
                                    label="Número:"
                                    placeholder="000"
                                    type="number"
                                    disabled={disabled}
                                    inputProps={{
                                        ...testID('number'),
                                        max: 99999,
                                        min: 0,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <POSFieldFormik
                                    name="complement"
                                    label="Complemento:"
                                    placeholder="Bloco/Apto"
                                    disabled={disabled}
                                    inputProps={{
                                        ...testID('complement'),
                                        maxLength: 500,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <POSFieldFormik
                                    required
                                    name="neighborhood"
                                    label="Bairro:"
                                    disabled={disabled}
                                    inputProps={{
                                        ...testID('neighborhood'),
                                        maxLength: 200,
                                        minLength: 2,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <POSFieldFormik
                                    required
                                    name="city"
                                    label="Cidade:"
                                    disabled={disabled}
                                    inputProps={{
                                        ...testID('city'),
                                        maxLength: 200,
                                        minLength: 2,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <POSFieldFormik
                                    required
                                    select
                                    name="state"
                                    label="Estado:"
                                    disabled={disabled}
                                    SelectProps={{
                                        MenuProps: {
                                            classes: {
                                                paper: classes.menuPaper,
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        ...testID('state'),
                                    }}
                                >
                                    {statesList.map(({ value }) => (
                                        <MenuItem value={value} key={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </POSFieldFormik>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <POSFieldFormik
                                    required
                                    name="birthdate"
                                    label="Data de Nascimento:"
                                    disabled={disabled}
                                    type="date"
                                    inputProps={{
                                        ...testID('birthdate'),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <POSFieldFormik
                                    required
                                    select
                                    name="gender"
                                    label="Sexo: (Conforme consta em seus documentos legais)"
                                    disabled={disabled}
                                    fieldType="gender"
                                    inputProps={{
                                        ...testID('gender'),
                                    }}
                                >
                                    {genderList.map(({ value, label }) => (
                                        <MenuItem value={value} key={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </POSFieldFormik>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <POSFieldFormik
                                    name="rg"
                                    label="Seu RG:"
                                    fieldType="rg"
                                    inputProps={{
                                        ...testID('rg'),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    flexDirection={{
                                        xs: 'column',
                                        sm: 'row',
                                    }}
                                >
                                    <Box order={{ xs: 1, sm: 0 }}>
                                        <Button
                                            onClick={() => {
                                                resetForm()
                                                onBack()
                                            }}
                                            disabled={disabled || isSubmitting}
                                            component={Link}
                                            underline="always"
                                            color="primary"
                                            style={{
                                                fontWeight: 700,
                                            }}
                                        >
                                            Voltar
                                        </Button>
                                    </Box>
                                    <Box
                                        width={{
                                            xs: '100%',
                                            sm: 320,
                                        }}
                                        mb={{ xs: 2, sm: 0 }}
                                        ml={{ xs: 0, sm: 4 }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            disabled={disabled || isSubmitting}
                                        >
                                            Prosseguir para pagamento
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </POSCard>
    )
}
