import { Link, Box, Typography, Button } from '@material-ui/core'

import { ReactComponent as YouTubeButton } from 'src/assets/images/youtube.svg'
import { ReactComponent as FacebookButton } from 'src/assets/images/facebook.svg'
import { ReactComponent as InstagramButton } from 'src/assets/images/instagram.svg'

import { testID } from 'src/config/test'

export const Help = () => {
    return (
        <Box
            display="flex"
            px={4}
            py={{ xs: 4, sm: 5 }}
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'center', md: 'baseline' }}
        >
            <Box
                mr={{ xs: 0, md: 6 }}
                width={{ xs: '100%', md: 243 }}
                mb={{ xs: 5 }}
                display="flex"
                flexDirection="column"
                alignItems={{ xs: 'center', md: 'baseline' }}
            >
                <Typography
                    color="primary"
                    variant="body1"
                    gutterBottom
                    style={{ fontWeight: 700 }}
                >
                    Precisa de ajuda?
                </Typography>
                <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    target="_blank"
                    rel="noreferrer"
                    href="https://api.whatsapp.com/send?phone=5511975964254&text=Ol%C3%A1%2C%20Equipe%20Sanar%20P%C3%B3s.%20Sou%20aluno(a)%20e%20estou%20com%20uma%20d%C3%BAvida.%20Podem%20me%20ajuda%3F"
                >
                    Ajuda e Suporte
                </Button>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                alignItems={{ xs: 'center', md: 'baseline' }}
            >
                <Typography
                    color="primary"
                    variant="body1"
                    gutterBottom
                    style={{ fontWeight: 700 }}
                >
                    Siga a Sanar Pós
                </Typography>
                <Box display="flex">
                    <Link
                        href="https://www.youtube.com/c/sanar"
                        target="_blank"
                        rel="noreferrer"
                        {...testID('youtube')}
                    >
                        <YouTubeButton />
                    </Link>
                    <Box mr={2} />
                    <Link
                        href="https://www.facebook.com/sanarpos"
                        target="_blank"
                        rel="noreferrer"
                        {...testID('facebook')}
                    >
                        <FacebookButton />
                    </Link>
                    <Box ml={2} />
                    <Link
                        href="https://www.instagram.com/sanarpos.med"
                        target="_blank"
                        rel="noreferrer"
                        {...testID('instagram')}
                    >
                        <InstagramButton />
                    </Link>
                </Box>
            </Box>

            <Box></Box>
        </Box>
    )
}
