import { CardBrand, Payments } from '@sanardigital/cross-payments-sdk'
import { env } from 'src/config/enviroments'
import { ICredCardParams } from 'src/utils/types/credit-card'

const PaymentEnvironments: any = env.ENVIRONMENT || 'dev'

const payments = new Payments(PaymentEnvironments)

export function paymentCompanyCodeToCardBrand(
    paymentCompanyCode: string
): CardBrand | null {
    switch (paymentCompanyCode) {
        case 'elo':
            return CardBrand.ELO
        case 'mastercard':
            return CardBrand.MASTERCARD
        case 'hipercard':
            return CardBrand.HIPERCARD
        case 'visa':
            return CardBrand.VISA
        default:
            return null
    }
}

export const createCardToken = async (
    creditCard: ICredCardParams
) => {
    const token = await payments.getCardPaymentToken({
        cardExpiration: creditCard.card_expiration,
        cardNumber: creditCard.card_number,
        cardSecurityCode: creditCard.card_cvv,
        holderName: creditCard.holder_name,
        cardBrand: paymentCompanyCodeToCardBrand(
            creditCard.payment_company_code
        ),
    })
    return token
}
