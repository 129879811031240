import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import React from 'react'

import { useLoadNewRelic, useMainContext } from 'src/hooks'

import { paths } from 'src/config/routes-paths'

import { POSLayout } from 'src/components'

import InitialPage from 'src/pages/initial'
import BasicDataPage from 'src/pages/basic-data'
import PaymentPage from 'src/pages/payment'
import ReviewPage from 'src/pages/review'
import SuccessPage from 'src/pages/success'
import CourseNotFoundPage from 'src/pages/course-not-found'

import { useFlagsStatus } from '@unleash/proxy-client-react'

import ErrorPage from 'src/pages/error'
import ContractRegularization from './pages/contract-regularization/ContractRegularization'
import ContractRegularizationConfirmation from './pages/contract-regularization-confirmation/ContractRegularizationConfirmation'

type TRoutesProps = {
    slug: string
    path?: string
}

const Routes = ({ slug, path = '' }: TRoutesProps) => {
    return (
        <Switch>
            <Route path={`${path}/${paths['contractRegularization']}`} exact>
                <ContractRegularization />
            </Route>

            <Route
                path={`${path}/${paths['contractRegularizationConfirmation']}`}
                exact
            >
                <ContractRegularizationConfirmation />
            </Route>

            <Route path={`${path}/${paths['not-found']}`} exact>
                <CourseNotFoundPage />
            </Route>

            <Route path={`${path}/:slug/${paths.success}`} exact>
                <SuccessPage />
            </Route>

            <Route path={`${path}/:slug/${paths.error}`} exact>
                <ErrorPage />
            </Route>

            <Route path={`${path}/:slug`} exact>
                <InitialPage />
            </Route>

            <Route path={`${path}/:slug/${paths['personal-data']}`} exact>
                <BasicDataPage />
            </Route>

            <Route path={`${path}/:slug/${paths['payment']}`} exact>
                <PaymentPage />
            </Route>

            <Route path={`${path}/:slug/${paths['review']}`} exact>
                <ReviewPage />
            </Route>

            <Route path={`${path}/*`}>
                <Redirect
                    to={
                        slug
                            ? `${path}/${slug}`
                            : `${path}/${paths['not-found']}`
                    }
                />
            </Route>
        </Switch>
    )
}

const App = () => {
    const { flagsReady, flagsError } = useFlagsStatus()

    useLoadNewRelic()
    const location = useLocation()

    const notifyUnleashErrorIsEnabled = React.useMemo<boolean>(
        () => flagsReady && flagsError,
        [flagsReady, flagsError]
    )

    const notifyUnleashErrorInNewRelic = React.useCallback(() => {
        if (!notifyUnleashErrorIsEnabled) return
        const error = new Error(`[Unleash] Error on init (${flagsError})`)
        window.newrelic.noticeError(error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifyUnleashErrorIsEnabled])

    React.useEffect(() => {
        notifyUnleashErrorInNewRelic()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { slug, maintainer } = useMainContext()

    const hasPagesWithLogo = location.pathname.includes(
        paths.success || paths.error || paths['not-found']
    )

    const hasInitialPage = location.pathname.split('/').length === 2

    return (
        <POSLayout
            hasBackgroundLogo={hasPagesWithLogo || hasInitialPage}
            maintainer={maintainer}
        >
            <Switch>
                <Routes path="/checkout" slug={slug} />
                <Routes slug={slug} />
            </Switch>
        </POSLayout>
    )
}

export default App
