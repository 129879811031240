import { Box, Theme, Typography, makeStyles } from '@material-ui/core'
import { formatCurrency } from '../../../../../utils'
import Tooltip from './Tooltip'
import { ModalProps, PLAN_TYPE } from '../utils'
import InstallmentItem from './InstallmentItem'

type Props = {
    showAllItems?: boolean
    tooltipText?: string
    testID: string
    showInstallmentsCycleHighlight?: boolean
    displayInstallmentType: 'price' | 'identifier'
} & ModalProps

const useStyles = makeStyles<
    Theme,
    { showAllItems?: boolean; showTooltip: boolean }
>((theme) => ({
    highlightInstallments: {
        borderRadius: '0.5rem',
        backgroundColor: '#25882F',
        padding: '0.5rem 1rem',
        alignItems: 'center',
        height: '2.25rem',
    },
    hightlightTypography: {
        color: 'rgba(255, 255, 255, 0.85)',
        fontWeight: 700,
        fontSize: '0.75rem',
    },
    installmentsWrapper: (props) => ({
        display: 'grid',
        overflowX: 'hidden',
        maxWidth: '19rem',
        position: 'relative',
        justifyContent: 'center',
        gridGap: '0.5rem',
        ...(props.showAllItems
            ? { gridTemplateColumns: 'repeat(auto-fit, minmax(auto, 44px))' }
            : { gridAutoFlow: 'column' }),
    }),
    fadeItems: {
        position: 'absolute',
        width: '30%',
        height: '100%',
        right: 0,
        background:
            'linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 40%)',
    },
    cycleHeaderTypography: {
        padding: '0.5rem 1rem',
        fontSize: '1rem',
        fontWeight: 700,
        color: 'rgba(17, 18, 18, 0.85)',
    },
    installmentsContainer: (props) => ({
        display: 'grid',
        gridAutoFlow: 'row',
        ...(props.showTooltip && {
            margin: '0 auto',
            width: 'max-content',
        }),
    }),
}))

const SectionContent = ({
    numberOfCycles,
    cycleInMonths,
    testID,
    installmentPrice,
    showInstallmentsCycleHighlight,
    totalInstallments,
    showAllItems,
    displayInstallmentType,
    tooltipText,
}: Props) => {
    const classes = useStyles({ showAllItems, showTooltip: !!tooltipText })

    const makeCycleInstallments = () => {
        const totalInstallmentsArr = Array.from(
            { length: totalInstallments },
            (_, i) => i + 1
        )

        return new Array(numberOfCycles).fill(null).map(() => {
            return totalInstallmentsArr.splice(0, cycleInMonths)
        })
    }

    return (
        <Box
            display="grid"
            gridGap="1.5rem"
            paddingTop="1.5rem"
            data-testid={testID}
        >
            {[...Array(numberOfCycles)].map((_, index) => (
                <Box
                    padding="0 1.5rem"
                    key={`${testID}-${index}`}
                    data-testid={`${testID}-${index + 1}`}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        bgcolor="#E9EBED"
                        borderRadius="0.5rem"
                        marginBottom="1rem"
                        alignSelf="stretch"
                        justifyContent="space-between"
                    >
                        <Typography className={classes.cycleHeaderTypography}>
                            {`${index + 1}º ${
                                PLAN_TYPE[cycleInMonths]['description']
                            }`}
                        </Typography>
                        {showInstallmentsCycleHighlight && (
                            <Box className={classes.highlightInstallments}>
                                <Typography
                                    className={classes.hightlightTypography}
                                >
                                    {`${cycleInMonths}x ${formatCurrency.format(
                                        installmentPrice
                                    )}`}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box className={classes.installmentsContainer}>
                        {tooltipText && <Tooltip text={tooltipText} />}
                        <Box className={classes.installmentsWrapper}>
                            {makeCycleInstallments()[index].map(
                                (installmentKey) => {
                                    const description =
                                        displayInstallmentType === 'identifier'
                                            ? `Mês ${installmentKey}`
                                            : formatCurrency.format(
                                                  installmentPrice
                                              )

                                    return (
                                        <InstallmentItem
                                            key={installmentKey}
                                            description={description}
                                            testID={`${testID}-installment`}
                                        />
                                    )
                                }
                            )}
                            {!showAllItems && (
                                <div className={classes.fadeItems} />
                            )}
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

export default SectionContent
