import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles<Theme, { hasCoupon: boolean }>((theme) => ({
    primary: {
        color: (props) =>
            props?.hasCoupon
                ? theme.palette.success.main
                : theme.palette.primary.main,
    },
    primaryDark: {
        color: theme.palette.primary.dark,
    },
    primaryLight: {
        color: theme.palette.primary.light,
    },
    secondary: {
        color: (props) =>
            props?.hasCoupon
                ? theme.palette.success.main
                : theme.palette.secondary.main,
    },
    immediate: {
        color: theme.palette.common.white,
    },
    immediateSecondary: {
        color: theme.palette.warning.light,
    },
    promoMainPrice: {
        color: theme.palette.primary.dark,
        fontSize: '18px',
        fontWeight: 700,
    },
}))
