import { FieldConfig, GenericFieldHTMLAttributes } from 'formik'
import { TextFieldProps } from '@material-ui/core'

import {
    POSFieldCardExpiration,
    POSFieldCep,
    POSFieldCpf,
    POSFieldCreditCard,
    POSFieldCvv,
    POSFieldPhone,
    POSFieldText,
    POSFieldRG,
    POSFieldGender,
    POSFieldCountryCode,
} from 'src/components'

const fields = {
    text: POSFieldText,
    phone: POSFieldPhone,
    cpf: POSFieldCpf,
    cvv: POSFieldCvv,
    creditCard: POSFieldCreditCard,
    expirationDate: POSFieldCardExpiration,
    cep: POSFieldCep,
    rg: POSFieldRG,
    gender: POSFieldGender,
    countryCode: POSFieldCountryCode,
}

export type POSFieldFormikProps = {
    fieldType?: keyof typeof fields
    enableMask?: boolean
} & GenericFieldHTMLAttributes &
    FieldConfig &
    TextFieldProps

export const POSFieldFormik = (props: POSFieldFormikProps) => {
    const { fieldType = 'text', ...rest } = props

    const Comp = fields[fieldType]

    return <Comp {...rest} />
}
