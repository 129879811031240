import { FieldConfig, GenericFieldHTMLAttributes, Field } from 'formik'
import {
    TextFieldProps,
    InputLabel,
    Tooltip,
    Button,
    withStyles,
    MuiThemeProvider,
    createTheme,
} from '@material-ui/core'

import { InfoOutlined } from '@material-ui/icons'

import { TextField } from 'formik-material-ui'

const defaultTheme = createTheme()
const InfoTooltip = withStyles({
    tooltip: {
        color: 'white',
        backgroundColor: '#002266',
        fontSize: '12px',
    },
})(Tooltip)

export type POSFieldGenderProps = GenericFieldHTMLAttributes &
    FieldConfig &
    TextFieldProps

export const POSFieldGender = ({ label, ...props }: POSFieldGenderProps) => (
    <>
        <InputLabel>
            {label}
            <MuiThemeProvider theme={defaultTheme}>
                <InfoTooltip
                    title="Para atender às exigências do MEC, essa pergunta aceita apenas 'masculino' e 'feminino' como opções. Valorizamos a diversidade de identidades de gênero e agradecemos sua compreensão ao fornecer essas informações para registro."
                    arrow
                    placement="top-end"
                >
                    <Button
                        style={{
                            backgroundColor: 'transparent',
                            cursor: 'default',
                            padding: 0,
                            marginLeft: '-20px',
                            color: '#5998F7',
                        }}
                        aria-label="delete"
                    >
                        <InfoOutlined style={{ fontSize: '16px' }} />
                    </Button>
                </InfoTooltip>
            </MuiThemeProvider>
        </InputLabel>
        <Field component={TextField} variant="filled" fullWidth {...props} />
    </>
)
