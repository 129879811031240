import { Typography } from '@material-ui/core'

import { POSCard } from 'src/components'

type NextStepProps = {
    matches?: boolean
    startDate: string
}

export const NextStep = ({ matches, startDate }: NextStepProps) => (
    <POSCard
        badgeTitle="Próximo passo"
        wrapperProps={{ my: 10, maxWidth: 968 }}
        px={{ xs: 2, md: 4 }}
    >
        <Typography
            variant={matches ? 'body2' : 'body1'}
            color="textSecondary"
            paragraph
        >
            <Typography
                component="span"
                variant="inherit"
                style={{ fontWeight: 700 }}
            >
                Agora é com você!
            </Typography>{' '}
            O seu curso terá início apenas no dia{' '}
            <Typography
                component="span"
                variant="inherit"
                style={{ fontWeight: 700 }}
            >
                {startDate}
            </Typography>{' '}
            , mas não te deixaremos sem conteúdo até lá.
        </Typography>
        <Typography
            variant={matches ? 'body2' : 'body1'}
            color="textSecondary"
            paragraph
        >
            Assim que seu pagamento for confirmado e sua matrícula validada,
            enviaremos para seu e-mail o acesso à nossa plataforma, onde você
            poderá acessar os primeiros passos de sua jornada e já começar a
            consumir conteúdos com a qualidade Sanar.
        </Typography>
        <Typography variant={matches ? 'body2' : 'body1'} color="textSecondary">
            Portanto,{' '}
            <Typography
                component="span"
                variant="inherit"
                style={{ fontWeight: 700 }}
            >
                fique ligado em sua caixa de entrada e spam
            </Typography>{' '}
            do e-mail cadastrado!
        </Typography>
    </POSCard>
)
