import { Fab, styled, darken, Box } from '@material-ui/core'

import WhatsAppIcon from '@material-ui/icons/WhatsApp'

import { testID } from 'src/config/test'

import { useTrackerContext, useMainContext } from 'src/hooks'

const WhatsAppButton = styled(Fab)(({ theme }) => ({
    backgroundColor: '#25D366',
    '&:hover': {
        backgroundColor: darken('#25D366', 0.1),
    },
    '& svg': {
        color: theme.palette.common.white,
    },
}))

export const POSWhatsAppButton = () => {
    const { track } = useTrackerContext()
    const { data } = useMainContext()

    const onClick = () => {
        track({
            eventName: 'Click whatsapp',
            additionalAttrs: {
                'Course name': data?.name,
                Route: window?.location?.href,
            },
        })
        window.open(
            'https://pos.sanarmed.com/consultora-comercial/?utm_source=checkout',
            '_blank'
        )
    }

    return (
        <Box position="fixed" bottom={16} right={16}>
            <WhatsAppButton onClick={onClick} {...testID('whats-app-button')}>
                <WhatsAppIcon fontSize="large" />
            </WhatsAppButton>
        </Box>
    )
}
