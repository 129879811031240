export type TUrlTogglesName = 'boleto' | 'coupon' | 'pos_promo'

export enum Toggle {
    BlockBankSlip = 'acquisitions_pos_checkout_block_bank_slip',
    BlockCoupon = 'acquisitions_pos_checkout_block_coupon',
    BlackFriday = 'acquisitions_pos_checkout_black_friday',
    Promo = 'acquisitions_pos_checkout_promo',
    ComparePrices = 'acquisitions_pos_checkout_compare_prices',
    Combo = 'acquisitions_pos_checkout_combo',
}
interface ICheckToggleFromURL {
    search: string
    toggleName: TUrlTogglesName
}

const isActiveFromURL = ({
    search,
    toggleName,
}: ICheckToggleFromURL): boolean => {
    const params = new URLSearchParams(search)
    return params.has(toggleName)
}

const toggles = { isActiveFromURL }

export default toggles
