import React from 'react'

import { Box, Button, Typography } from '@material-ui/core'

import { ReactComponent as LogoSymbol } from 'src/assets/images/logo-symbol.svg'

import { POSCard, POSVerifyRouteAccess } from '..'
import { SubmitWhatsAppAccept, WhatsAppAccept } from '../whatsapp-accept'
import { Buyer } from 'src/providers/Main'
import { IComboVariant } from '../../types'

type SuccessTextProps = {
    matches?: boolean
    buyer?: Partial<Buyer>
    onSubmit: SubmitWhatsAppAccept
    title: string
    subtitle: string
    shouldShowComboBanner: boolean
    next: IComboVariant['next'] | undefined
    goToComboCheckout: () => void
}

export const SuccessText = ({
    matches,
    buyer,
    onSubmit,
    title,
    subtitle,
    shouldShowComboBanner,
    next,
    goToComboCheckout,
}: SuccessTextProps) => {
    return (
        <>
            <POSVerifyRouteAccess condition={!buyer?.phone} />
            <Box display="inline-flex" alignItems="center" mb={3}>
                <LogoSymbol />
                <Box mr={1} />
                <Typography
                    color="primary"
                    variant={matches ? 'h4' : 'h3'}
                    style={{ fontWeight: 700 }}
                >
                    Quase lá!
                </Typography>
            </Box>
            <Box maxWidth={594}>
                <Typography
                    variant={matches ? 'subtitle1' : 'h5'}
                    paragraph
                    color="textSecondary"
                    style={{ fontWeight: 500 }}
                >
                    {title}
                </Typography>
            </Box>

            {shouldShowComboBanner && (
                <POSCard
                    badgeTitle="Combo"
                    wrapperProps={{
                        maxWidth: 968,
                        marginBottom: 4,
                        marginTop: 4,
                    }}
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'column' }}
                    justifyContent="space-between"
                    gridGap={8}
                    px={{ xs: 2, md: 4 }}
                    pb={{ xs: 2, md: 4 }}
                >
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        style={{ fontWeight: 700 }}
                    >
                        Você ainda tem mais um curso no combo! Garanta seu
                        acesso concluíndo o pagamento.
                    </Typography>
                    <Typography
                        variant="h5"
                        color="primary"
                        paragraph
                        style={{ fontWeight: 700 }}
                    >
                        {next?.name}
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 'auto' }}
                        onClick={goToComboCheckout}
                    >
                        Prosseguir
                    </Button>
                </POSCard>
            )}

            <Box maxWidth={503}>
                <Typography
                    variant={matches ? 'body2' : 'body1'}
                    color="textSecondary"
                >
                    {subtitle}
                </Typography>
            </Box>

            <WhatsAppAccept
                matches={matches}
                phone={buyer?.phone}
                countryCode={buyer?.countryCode}
                onSubmit={onSubmit}
            />
        </>
    )
}
