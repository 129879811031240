import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'

import { axiosInstance } from 'src/config/data-access/axios'

export interface Signature {
    fullName: string
    email: string
    cpf: string
}

export const useRegisterSignature = () => {
    const { search } = useLocation()
    const utmSource = search.split('?utm_source=')[1]

    return useMutation(async (signature: Signature): Promise<Signature> => {
        const body = {
            fullName: signature.fullName,
            cpf: signature.cpf,
            email: signature.email,
        }

        const headers = {
            'x-utm-source': JSON.stringify(utmSource),
        }

        const { data } = await axiosInstance.post(
            '/v1/contract/signature',
            body,
            { headers }
        )

        return data
    })
}
