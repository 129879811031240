import MaskedInput, { MaskedInputProps } from 'react-text-mask'

import {
    TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core'

import { fieldToTextField, TextFieldProps } from 'formik-material-ui'

type TextMaskCustomProps = {
    inputRef: (ref: HTMLInputElement | null) => void
} & MaskedInputProps

const TextMaskCustom = (props: TextMaskCustomProps) => {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            guide={false}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null)
            }}
            {...other}
        />
    )
}

export type POSTextMaskProps = {
    startAdornment?: React.ReactNode
    endAdornment?: React.ReactNode
} & MuiTextFieldProps &
    TextFieldProps &
    MaskedInputProps

export const POSTextMask = (props: POSTextMaskProps) => (
    <MuiTextField
        {...props}
        InputProps={{
            inputComponent: TextMaskCustom as any,
            inputProps: {
                ...props?.inputProps,
                mask: props?.mask,
            },
            startAdornment: props.startAdornment || undefined,
            endAdornment: props.endAdornment || undefined,
        }}
        {...fieldToTextField(props)}
    />
)
