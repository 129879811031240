import React, { createContext, useCallback, useMemo, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Signature, useGetProducts, useQuery } from 'src/hooks'

import { paths } from 'src/config/routes-paths'

import {
    IComboVariant,
    IContract,
    ICourse,
    IProduct,
    PaymentMethodType,
} from 'src/types'

import { IdentificationFormValues } from 'src/pages/basic-data/Identification'
import { AdditionalInformationFormValues } from 'src/pages/basic-data/AdditionalInfo'

import { CreditCardFormValues } from 'src/pages/payment/CreditCard'
import { BankSlipFormValues } from 'src/pages/payment/BankSlip'

import { getCurrentSelectedProduct, makeCourse } from './utils'
import toggles, { Toggle } from 'src/config/toggles'
import { hasBankSlipToggle } from 'src/utils/has-bank-slip-toggle'
import { isPromoToggleManualSet } from 'src/utils/has-promo-toggle'
import { hasToggle } from '../../utils/has-toggle'
import { isBlackFridayToggleManualSet } from 'src/utils/has-black-friday-toggle'
import * as campaigns from 'src/config/toggles/campaigns'
import { useFlag, useVariant } from '@unleash/proxy-client-react'

export interface IProductData extends IProduct {
    numberOfCyles: number
    cycleInMonths: number
    cycleTotalPrice: number
    totalInstallments: number
    discountTotalValue: any
    totalValueToPay: number
    discountsPercentageTotal: number
    installmentPrice: number
    uniqueId: string
    customDescription?: string
    finalFullPrice: number
    priceFirstInstallment?: number
    planSlug?: string
    availableBillingDays?: number[]
    isFirstChargeReductionCampaign?: boolean
}

export interface ICourseData extends ICourse {
    duration: number
    products: IProductData[]
}

export type Buyer = IdentificationFormValues &
    BankSlipFormValues & {
        promoCode?: string
        address?: AdditionalInformationFormValues
        creditCard?: Omit<CreditCardFormValues, 'billingDay'>
        paymentMethod?: PaymentMethodType
        recaptcha?: string
        billingDay?: string
    }

export enum Maintainer {
    'CETRUS' = 'CETRUS',
}

type MainContextProps = {
    isLoading: boolean
    isError: boolean
    data: ICourseData

    primaryProduct?: IProductData
    buyer?: Partial<Buyer>
    setBuyer: React.Dispatch<React.SetStateAction<Partial<Buyer> | undefined>>
    productSelectValue: string
    setProductSelectValue: React.Dispatch<React.SetStateAction<Partial<string>>>
    paymentMethodSelectValue: PaymentMethodType
    setCourse: React.Dispatch<React.SetStateAction<ICourseData>>
    productSelected?: IProductData

    utmSource?: string
    utmMedium?: string
    utmCampaign?: string

    goToBasicData: () => void
    goToPayment: () => void
    goToReview: () => void
    goToRoute: (route: string) => void
    goToContractPage: () => void
    slug: string

    signature: Signature
    setSignature: React.Dispatch<React.SetStateAction<Signature>>

    contract?: IContract
    maintainer?: Maintainer
    isPromoEnabled: boolean
    isBlackFridayEnabled: boolean
    isBankSlipEnabled: boolean
    isRecurrenceEnabled: boolean
    isCouponEnabled: boolean
    comparePricesFlag: boolean
    comboFlag: boolean
    comboVariant: IComboVariant[]
    installmentValueWithCoupon?: number
    setInstallmentValueWithCoupon: React.Dispatch<
        React.SetStateAction<number | undefined>
    >
    totalValueWithCoupon?: number
    setTotalValueWithCoupon: React.Dispatch<
        React.SetStateAction<number | undefined>
    >
}

export const MainContext = createContext<MainContextProps>(
    {} as MainContextProps
)

export const MainProvider: React.FC = ({ children }) => {
    const history = useHistory()
    const { search } = useLocation()

    const blockBankSlipFlag = useFlag(Toggle.BlockBankSlip)
    const blockCouponFlag = useFlag(Toggle.BlockCoupon)
    const blackFridayFlag = useFlag(Toggle.BlackFriday)
    const promoFlag = useFlag(Toggle.Promo)
    const comparePricesFlag = useFlag(Toggle.ComparePrices)
    const comboFlag = useFlag(Toggle.Combo)

    const comboVariant = JSON.parse(
        useVariant(Toggle.Combo).payload?.value || '[]'
    ) as IComboVariant[]

    const couponQuery = useQuery().get('cupom') ?? ''
    const utmSource = useQuery().get('utm_source') ?? ''
    const utmMedium = useQuery().get('utm_medium') ?? ''
    const utmCampaign = useQuery().get('utm_campaign') ?? ''

    const maintainer = Maintainer.CETRUS

    const [course, setCourse] = useState<ICourseData>({} as ICourseData)
    const [contract, setContract] = useState<IContract>()
    const [buyer, setBuyer] = useState<Buyer>()
    const [primaryProduct, setPrimaryProduct] = useState<IProductData>(
        {} as IProductData
    )
    const [signature, setSignature] = useState<Signature>({} as Signature)

    const pathname = history.location.pathname.replace('/checkout', '')
    const slug = pathname.split('/')[1]

    const isBankSlipEnabled = !blockBankSlipFlag || hasBankSlipToggle(search)

    const isPromoEnabled = promoFlag || isPromoToggleManualSet(search)

    const isBlackFridayEnabled =
        blackFridayFlag || isBlackFridayToggleManualSet(search)

    let isCouponEnabled =
        !blockCouponFlag ||
        toggles.isActiveFromURL({ search: search, toggleName: 'coupon' })

    const activeCampaign = campaigns.getActiveCampaignBy(slug)

    if (activeCampaign) {
        isCouponEnabled = activeCampaign?.definitions.enabledCouponField
    }

    const isRecurrenceEnabled = hasToggle(search, 'recurrence')

    const [productSelectValue, setProductSelectValue] = useState('')

    const paymentMethodSelectValue =
        !isRecurrenceEnabled && isBankSlipEnabled
            ? PaymentMethodType.BANK_SLIP
            : PaymentMethodType.CREDIT_CARD

    const [installmentValueWithCoupon, setInstallmentValueWithCoupon] =
        useState<number | undefined>()

    const [totalValueWithCoupon, setTotalValueWithCoupon] = useState<
        number | undefined
    >()

    const { isLoading, isError } = useGetProducts(
        {
            slug,
            coupon: couponQuery,
            recurrence: isRecurrenceEnabled,
            boleto: isBankSlipEnabled,
        },
        {
            onError: () => {
                goToRoute('/nao-encontrado')
            },
            retry: false,
            enabled: !Object.values(paths).includes(slug),
            onSuccess: (data) => {
                const courseFormatted = makeCourse(data, isBankSlipEnabled)

                setCourse(courseFormatted)
                setBuyer((prev) => ({
                    ...prev,
                    promoCode: data.coupon ? data?.coupon.promoCode : '',
                }))

                if (data.contract) setContract(data.contract)

                if (productSelectValue === '') {
                    const productFind =
                        courseFormatted?.products?.find(
                            (product) =>
                                product.paymentMethod ===
                                    paymentMethodSelectValue &&
                                product.isPrimary
                        ) ?? courseFormatted?.products?.[0]

                    setPrimaryProduct(productFind)
                }
            },
        }
    )

    const productSelected = getCurrentSelectedProduct(
        productSelectValue,
        course?.products
    )

    const goToRoute = useCallback(
        (route: string) => {
            const pathBase = history.location.pathname.includes('/checkout')
                ? '/checkout'
                : ''

            const path = `${pathBase}` + route + history.location.search
            history.push(path)
        },
        [history]
    )

    const goToBasicData = useCallback(
        () => goToRoute(`/${slug}/dados-pessoais`),
        [goToRoute, slug]
    )

    const goToContractPage = useCallback(
        () => goToRoute(paths['contractRegularization']),
        [goToRoute]
    )

    const goToPayment = useCallback(
        () => goToRoute(`/${slug}/pagamento`),
        [goToRoute, slug]
    )

    const goToReview = useCallback(
        () => goToRoute(`/${slug}/revisar-informacoes`),
        [goToRoute, slug]
    )

    const memoizedValue = useMemo(
        () => ({
            isLoading,
            isError,
            data: course,
            primaryProduct,
            buyer,
            setBuyer,
            productSelectValue,
            setProductSelectValue,
            paymentMethodSelectValue,
            productSelected,
            setCourse,
            utmSource,
            utmMedium,
            utmCampaign,
            goToBasicData,
            goToPayment,
            goToReview,
            goToRoute,
            goToContractPage,
            slug,
            maintainer,
            signature,
            setSignature,
            contract,
            isPromoEnabled,
            isBankSlipEnabled,
            isRecurrenceEnabled,
            isBlackFridayEnabled,
            isCouponEnabled,
            comparePricesFlag,
            totalValueWithCoupon,
            setTotalValueWithCoupon,
            installmentValueWithCoupon,
            setInstallmentValueWithCoupon,
            comboFlag,
            comboVariant,
        }),
        [
            isLoading,
            isError,
            course,
            primaryProduct,
            buyer,
            setBuyer,
            productSelectValue,
            setProductSelectValue,
            paymentMethodSelectValue,
            productSelected,
            setCourse,
            utmSource,
            utmMedium,
            utmCampaign,
            goToBasicData,
            goToPayment,
            goToReview,
            goToRoute,
            goToContractPage,
            slug,
            maintainer,
            signature,
            setSignature,
            contract,
            isPromoEnabled,
            isBankSlipEnabled,
            isRecurrenceEnabled,
            isBlackFridayEnabled,
            isCouponEnabled,
            comparePricesFlag,
            totalValueWithCoupon,
            setTotalValueWithCoupon,
            installmentValueWithCoupon,
            setInstallmentValueWithCoupon,
            comboFlag,
            comboVariant,
        ]
    )

    return (
        <MainContext.Provider value={memoizedValue}>
            {children}
        </MainContext.Provider>
    )
}
