const currencyBase = {
    style: 'currency',
    currency: 'BRL',
}

export const formatCurrency = new Intl.NumberFormat('pt-BR', currencyBase)

export const formatCurrencyWithoutCents = new Intl.NumberFormat('pt-BR', {
    ...currencyBase,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
})
