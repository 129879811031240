import { Box, Typography } from '@material-ui/core'

import { ReactComponent as LogoVertical } from 'src/assets/images/logo-vertical.svg'
import { ReactComponent as LogoCetrus } from 'src/assets/images/logo-cetrus-branco.svg'

import logoHorizontalPng from 'src/assets/images/logo-horizontal.png'
import logoHorizontalWebp from 'src/assets/images/logo-horizontal.webp'

import { Maintainer } from 'src/providers/Main'
import { useMainContext } from 'src/hooks'
import { POSImageWithFallback } from 'src/components/image-with-fallback'

export const LeftFooter = () => {
    const { maintainer } = useMainContext()

    const logo = {
        [Maintainer.CETRUS]: (
            <Box mb={0.2}>
                <LogoCetrus width={104} />
            </Box>
        ),
    }

    return (
        <Box
            bgcolor="primary.main"
            color="common.white"
            width={{ xs: '100%', sm: '50%', lg: 'calc(50% + 40px)' }}
            height="auto"
            pl={{ xs: 4, lg: 10 }}
            pr={{ xs: 4, lg: 6 }}
            py={5}
        >
            <LogoVertical />
            <Box mt={5} />
            <Typography paragraph variant="body1" style={{ fontWeight: 500 }}>
                A Sanar é uma empresa para toda a vida dos médicos, resolvendo
                seus problemas como ninguém.
            </Typography>
            <Typography variant="body1">
                Nós existimos para ajudar médicos a fazer a medicina cada vez
                melhor com conteúdo confiável, produtos incríveis e parceria
                durante toda a jornada profissional.
            </Typography>

            <Box bgcolor="common.white" height={3} width="100%" my={5} />

            <Typography paragraph variant="body1">
                Atendimento: Seg. a Sex.: 09h-18h | 0800-3376-262
            </Typography>
            <Typography variant="body1">
                © 2021 - Todos os Direitos Reservados - R. Alceu Amoroso Lima,
                172 - Salvador Office & Pool, 3ro Andar - Caminho das Árvores,
                CEP 41820-770, Salvador - BA - Brasil Tel.: 71 3052-4831 Editora
                Sanar LTDA - ME. CNPJ: 18.990.682/0001-92
            </Typography>

            <Box display="flex" alignItems="center" mt={7}>
                <POSImageWithFallback
                    src={logoHorizontalWebp}
                    fallback={logoHorizontalPng}
                />
                <Box mr={3} />

                {!!maintainer && logo[maintainer]}
            </Box>
        </Box>
    )
}
