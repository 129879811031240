import MuiModal, { ModalProps } from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'

interface Props extends ModalProps {}

const Modal = (props: Props) => {
    return (
        <MuiModal
            style={{
                display: 'grid',
                placeItems: 'center',
                overflowY: 'scroll',
            }}
            {...props}
        >
            <Paper
                style={{
                    padding: 16,
                }}
                elevation={10}
            >
                {props.children}
            </Paper>
        </MuiModal>
    )
}

export default Modal
