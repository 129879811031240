export const env = {
    ENVIRONMENT: window.env.ENVIRONMENT,
    API_URL: window.env.API_URL,
    CHECKOUT_BFF_URL: window.env.CHECKOUT_BFF_URL,
    RE_CAPTCHA: window.env.RE_CAPTCHA,
    CONSULTANT_LINK: 'https://pos.sanarmed.com/consultora-comercial/',
    HOME_LINK: window.env.HOME_LINK,
    AMPLITUDE_KEY: window.env.AMPLITUDE_KEY,
    MIXPANEL_KEY: window.env.MIXPANEL_KEY,
    CONTRACT_LINK: 'http://sanar.link/contrato-posmed',
    VINDI_TOKEN: window.env.VINDI_TOKEN,
    VINDI_API_URL: window.env.VINDI_API_URL,
    MGM_URL: 'https://pos.sanarmed.com/clubesanarpos/indiqueumcolega/',
    POS_CONTRACT:
        'https://sanarmed-pos-assets.s3.amazonaws.com/SanarContratoPosV3.pdf',
    POS_CONTRACT_REGULARIZATION:
        'https://sanarmed-pos-assets.s3.amazonaws.com/SanarContratoPosV2.pdf',
    POS_PRIVACY:
        'https://sanarmed-pos-assets.s3.amazonaws.com/POLiTICA_DE_PRIVACIDADE_SANAR.pdf',
    POS_CANCELLATION:
        'https://sanarmed-pos-assets.s3.amazonaws.com/distrato-sanar-pos.pdf',
    UPDATE_DOCUMENTS:
        'https://docs.google.com/forms/d/1rP-lrwka4WZkyMtmDd6Gr9PDxWHU-D1MZQz3tyqqzWQ/edit?usp=drive_web',
    UPDATE_DOCUMENTS_REGULARIZATION: 'https://forms.gle/1tLcEYuwtdgx6X3D9',
    ENABLE_HUBSPOT: window.env.ENABLE_HUBSPOT,
    HUBSPOT_PORTAL_ID: window.env.HUBSPOT_PORTAL_ID,
    HUBSPOT_REGISTRATION_FORM_ID: window.env.HUBSPOT_REGISTRATION_FORM_ID,
    HUBSPOT_PAYMENT_FORM_ID: window.env.HUBSPOT_PAYMENT_FORM_ID,
    UNLEASH_URL: window.env.UNLEASH_URL,
    UNLEASH_SECRET: window.env.UNLEASH_SECRET,
}

export type Env = typeof env
