import { Field, getIn, FieldAttributes } from 'formik'

import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import clsx from 'clsx'

import ReCAPTCHA from 'react-google-recaptcha'

import { env } from 'src/config/enviroments'

export type POSFieldRecaptchaProps<T> = FieldAttributes<T>

const useStyles = makeStyles({
    root: {},
    disabled: {
        pointerEvents: 'none',
    },
})

export const CustomRecaptcha = (props: POSFieldRecaptchaProps<any>) => {
    const classes = useStyles()

    const fieldError = getIn(props?.form?.errors, props?.field.name)

    return (
        <Box
            className={clsx(classes.root, props?.disabled && classes.disabled)}
        >
            <ReCAPTCHA
                sitekey={env.RE_CAPTCHA}
                onChange={(key) =>
                    props.form.setFieldValue(props?.field.name, key)
                }
            />
            {fieldError && <FormHelperText error>{fieldError}</FormHelperText>}
        </Box>
    )
}

export const POSFieldRecaptcha = ({
    label,
    ...props
}: POSFieldRecaptchaProps<any>) => (
    <>
        <InputLabel>{label}</InputLabel>
        <Field
            component={CustomRecaptcha}
            variant="filled"
            fullWidth
            {...props}
        />
    </>
)
