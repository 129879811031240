import { isAfter, isBefore } from "date-fns";

interface CampaignDefinitions {
  enabledCouponField: boolean,
}

interface Campaigns {
  [key: string]:  {
      startAt: Date,
      endAt: Date,
      definitions: CampaignDefinitions
  },
}

const campaigns: Campaigns = {
  'nutrologia-2024.2': {
      startAt: new Date('2024-04-04T00:00:00'),
      endAt: new Date('2024-04-12T23:59:59'),
      definitions: {
          enabledCouponField: false,
      }
  },
  'terapia-intensiva-2024.2': {
      startAt: new Date('2024-04-04T00:00:00'),
      endAt: new Date('2024-04-12T23:59:59'),
      definitions: {
          enabledCouponField: false,
      }
  },
  'medicina-do-trabalho-2024.2': {
      startAt: new Date('2024-04-24T00:00:00'),
      endAt: new Date('2024-04-30T23:59:59'),
      definitions: {
          enabledCouponField: false,
      },
  },
}

export const getActiveCampaignBy = (courseSlug: string): {
  startAt: Date;
  endAt: Date;
  definitions: CampaignDefinitions;
} | undefined => {
  const campaign = campaigns[courseSlug]

  if (!campaign) {
      return;
  }

  const campaignIsEnabled = isAfter(new Date(), campaign.startAt) && isBefore(new Date(), campaign.endAt);

  return campaignIsEnabled ? campaign : undefined;
}