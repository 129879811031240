import { useEffect } from 'react'

import {
    Box,
    Typography,
    useMediaQuery,
    useTheme,
    Button,
} from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import { ReactComponent as LogoSymbol } from 'src/assets/images/logo-symbol.svg'

import { env } from 'src/config/enviroments'

import { paths } from 'src/config/routes-paths'

import { useTrackerContext, useQuery } from 'src/hooks'

const stepToText = {
    [paths.initial]:
        'Parece que <strong>tivemos um erro interno </strong>. Tente novamente mais tarde, ou entre em contato com um de nossos consultores para concluir sua compra.',
    [paths['personal-data']]:
        'Parece que <strong>tivemos um erro na etapa 1-Dados Básicos.</strong> Volte até essa etapa e tente novamente. Se o problema persistir, entre em contato com um de nossos consultores para concluir sua compra.',
    [paths.payment]:
        'Sua compra <strong>não foi finalizada</strong>. Você deve ter preenchido algum dado incorretamente ou pode ter ocorrido algum problema com a operadora do cartão.',
}

const Error = () => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    const history = useHistory()
    const stepBack = useQuery().get('step') || paths.initial
    const step = stepToText[stepBack]
    const handleBack = () => {
        history.push(`./${stepBack}` + history.location.search)
    }

    const { track } = useTrackerContext()

    useEffect(() => {
        track({
            eventName: 'Error loaded',
            additionalAttrs: {},
        })
    }, [track])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Box mb={{ xs: 8, md: 64 }} maxWidth={594}>
            <Box display="inline-flex" alignItems="center" mb={3}>
                <LogoSymbol />
                <Box mr={1} />
                <Typography
                    color="primary"
                    variant={matches ? 'h4' : 'h3'}
                    style={{ fontWeight: 700 }}
                >
                    Ops!
                </Typography>
            </Box>

            <Typography
                variant={matches ? 'body1' : 'h5'}
                dangerouslySetInnerHTML={{ __html: step }}
            />

            <Box
                mt={4}
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                width={{ xs: '100%', sm: 504 }}
            >
                <Box flex={1}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={handleBack}
                    >
                        Volte e tente novamente
                    </Button>
                </Box>
                <Box ml={{ xs: 0, sm: 3 }} mt={{ xs: 2, sm: 0 }} flex={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        href={env.CONSULTANT_LINK}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Fale conosco
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Error
