import React from 'react'

import mediaQuery from 'css-mediaquery'

import { Router } from 'react-router-dom'
import { createMemoryHistory } from 'history'

import { Formik, Form, FormikConfig } from 'formik'

import { render, RenderOptions } from '@testing-library/react'

import { MaterialUIProvider } from 'src/providers/MaterialUI'
import { TrackerProvider } from 'src/providers/Tracker'

const history = createMemoryHistory()

const ProvidersForTests = ({ children }: React.PropsWithChildren<{}>) => (
    <MaterialUIProvider>
        <Router history={history}>
            <TrackerProvider trackers={[]}>{children}</TrackerProvider>
        </Router>
    </MaterialUIProvider>
)

const customRender = (ui: React.ReactElement, options?: RenderOptions) =>
    render(ui, { wrapper: ProvidersForTests, ...options })

export const renderWithFormik = (
    children: React.ReactElement,
    options?: FormikConfig<any>
) => (
    <Formik onSubmit={console.log} initialValues={{}} {...options}>
        {() => (
            <Form>
                {children}
                <button type="submit">submit</button>
            </Form>
        )}
    </Formik>
)

export const createMatchMedia = (width: number) => {
    return (query: string) => ({
        matches: mediaQuery.match(query, { width }),
        addListener: () => {},
        removeListener: () => {},
    })
}

export const testID = (id: string) => {
    return { 'data-testid': id }
}

export * from '@testing-library/react'
export { customRender as render }
