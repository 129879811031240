import { useState, useEffect, useRef } from 'react'

import { Box, Typography, Link } from '@material-ui/core'

import { Formik, Form, FormikHelpers } from 'formik'

import { ReactComponent as WhatsappSymbol } from 'src/assets/images/whatsapp.svg'

import { POSFieldFormik, POSFieldCheckbox } from 'src/components'

import { testID } from 'src/config/test'

type WhatsAppAcceptProps = {
    matches?: boolean
    phone?: string
    countryCode?: string
    onSubmit: SubmitWhatsAppAccept
}

type WhatsAppAcceptFormValues = { phone?: string; countryCode?: string; acceptWhatsapp: boolean }

export type SubmitWhatsAppAccept = (
    values: WhatsAppAcceptFormValues,
    formik?: FormikHelpers<WhatsAppAcceptFormValues>
) => Promise<void>

type FormikObserverProps = {
    values: Pick<WhatsAppAcceptFormValues, 'acceptWhatsapp'>
    onChange: (values: Pick<WhatsAppAcceptFormValues, 'acceptWhatsapp'>) => void
}

const FormikObserver = ({ values, onChange }: FormikObserverProps) => {
    const prevValue = useRef(values)

    useEffect(() => {
        if (prevValue.current?.acceptWhatsapp !== values?.acceptWhatsapp) {
            onChange(values)

            prevValue.current = values
        }
    }, [values, onChange])

    return null
}

export const WhatsAppAccept = ({
    matches,
    phone = '(00) 00000-0000',
    countryCode = '+55',
    onSubmit,
}: WhatsAppAcceptProps) => {
    const fontSize = matches ? 'caption' : 'body1'

    const [isChangePhone, setChangePhone] = useState(false)

    const handleChangePhone = (event: React.SyntheticEvent) => {
        event.preventDefault()
        setChangePhone((prev) => !prev)
    }

    const handleSubmit: SubmitWhatsAppAccept = (values, formik) => {
        setChangePhone(false)
        return onSubmit(values, formik)
    }

    const handleSubmitWhatAppAccept = (
        values: Pick<WhatsAppAcceptFormValues, 'acceptWhatsapp'>
    ) => {
        onSubmit(values)
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={{ phone, countryCode, acceptWhatsapp: true } as any}
        >
            {({ values }) => (
                <Form>
                    <FormikObserver
                        values={{ acceptWhatsapp: values?.acceptWhatsapp }}
                        onChange={handleSubmitWhatAppAccept}
                    />
                    <Box
                        display="flex"
                        alignItems="center"
                        mt={{ xs: 3, md: 4 }}
                        mb={{ xs: 6, md: 18 }}
                    >
                        <POSFieldCheckbox
                            name="acceptWhatsapp"
                            inputProps={{ ...testID('accept-whats-app') }}
                        />
                        <Box
                            border="1px solid"
                            borderColor="grey.300"
                            borderRadius={5}
                            p={{ xs: 1, md: 2 }}
                            display="flex"
                            alignItems="center"
                            maxWidth={468}
                        >
                            <WhatsappSymbol />
                            <Box ml={{ xs: 1, md: 2 }} />
                            <Typography
                                variant={fontSize}
                                color="textSecondary"
                                component="div"
                                style={{
                                    color: '#414545',
                                }}
                            >
                                Aceito receber informações sobre o meu curso por
                                WhatsApp
                                {isChangePhone ? (
                                    <Box
                                        display="inline-flex"
                                        mx={1}
                                        gridGap={2}
                                    >
                                        <POSFieldFormik
                                            name="countryCode"
                                            size="small"
                                            variant="outlined"
                                            style={{ maxWidth: '4.375rem' }}
                                            inputProps={{
                                                ...testID('input-country-code'),
                                                maxLength: 4
                                            }}
                                        />
                                        <POSFieldFormik
                                            name="phone"
                                            fieldType="phone"
                                            size="small"
                                            style={{ maxWidth: '9.375rem' }}
                                            variant="outlined"
                                            inputProps={{
                                                ...testID('input-phone'),
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    ` ${values?.countryCode} ${values?.phone} `
                                )}
                                {isChangePhone ? (
                                    <Link
                                        underline="always"
                                        type="submit"
                                        component="button"
                                        variant={fontSize}
                                    >
                                        Salvar
                                    </Link>
                                ) : (
                                    <Link
                                        component="button"
                                        underline="always"
                                        variant={fontSize}
                                        onClick={handleChangePhone}
                                        style={{
                                            color: '#0050F0',
                                        }}
                                    >
                                        Alterar
                                    </Link>
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}
