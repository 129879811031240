import Skeleton from '@material-ui/lab/Skeleton'
import { Box } from '@material-ui/core'

export const SkeletonInitial = () => (
    <Box flex={1}>
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
    </Box>
)
