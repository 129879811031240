import { Box } from '@material-ui/core'
import React from 'react'

import InfoHeader from './InfoHeader'

type Props = {
    sectionTitle: React.ReactNode
    sectionContent: React.ReactNode
    index: number
}

const ModalSection: React.FC<Props> = ({
    sectionTitle,
    sectionContent,
    index,
}) => {
    return (
        <Box
            padding={2}
            boxShadow="0px 1px 4px 0px rgba(17, 18, 18, 0.25)"
            borderRadius="0.5rem"
            role="region"
        >
            <InfoHeader index={index} testId={`modal-section-${index}`}>
                {sectionTitle}
            </InfoHeader>
            <Box
                maxHeight={'24rem'}
                overflow={{ xs: 'hidden', md: 'auto' }}
                mx={'-17px'}
                position={'relative'}
            >
                {sectionContent}
                <div
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        background:
                            'linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 70%)',
                        width: '100%',
                        height: '2rem',
                    }}
                />
            </Box>
        </Box>
    )
}

export default ModalSection
