import { FieldConfig, GenericFieldHTMLAttributes, Field } from 'formik'
import { TextFieldProps, InputLabel } from '@material-ui/core'

import { INPUT_PHONE_MASK } from 'src/utils/masks'

import { POSFieldText, POSTextMask } from 'src/components'

export type POSFieldPhoneProps = GenericFieldHTMLAttributes &
    FieldConfig &
    TextFieldProps

export const POSFieldPhone = ({ label,enableMask, ...props }: POSFieldPhoneProps & { enableMask?: boolean }) => (
    <>
        <InputLabel>{label}</InputLabel>
        <Field
            component={!!enableMask ? POSTextMask : POSFieldText}
            mask={INPUT_PHONE_MASK}
            variant="filled"
            type="phone"
            fullWidth
            {...props}
            inputProps={{
                ...props?.inputProps,
                inputMode: 'tel',
            }}
        />
    </>
)
