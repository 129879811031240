import {
    Container,
    Box,
    Divider,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'

import SecurityIcon from '@material-ui/icons/Security'

import { ReactComponent as LogoBackground } from 'src/assets/images/logo-background.svg'
import { ReactComponent as LogoSanarPos } from 'src/assets/images/logo-sanar.svg'
import { ReactComponent as LogoCetrus } from 'src/assets/images/logo-cetrus.svg'

import logoPciPng from 'src/assets/images/logo-pci.png'
import logoPciWebp from 'src/assets/images/logo-pci.webp'
import logoVindiPng from 'src/assets/images/logo-vindi.png'
import logoVindiWebp from 'src/assets/images/logo-vindi.webp'
import logoAmazonPng from 'src/assets/images/logo-amazon.png'
import logoAmazonWebp from 'src/assets/images/logo-amazon.webp'

import { testID } from 'src/config/test'

import { Maintainer } from 'src/providers/Main'
import { POSFooter, POSImageWithFallback } from 'src/components'

type LayoutProps = {
    children: React.ReactNode
    hasBackgroundLogo?: boolean
    maintainer?: Maintainer
}

export const POSLayout = ({
    children,
    hasBackgroundLogo,
    maintainer,
}: LayoutProps) => {
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))

    const hasShowBackgroundLogo = hasBackgroundLogo && isSm

    const logo = {
        [Maintainer.CETRUS]: (
            <Box
                display="flex"
                alignItems="center"
                maxWidth={isSm ? 355 : 251}
                justifyContent="space-between"
            >
                <LogoSanarPos width={isSm ? 188 : 132} height={37} />
                <LogoCetrus width={isSm ? 144 : 105} height={37} />
            </Box>
        ),
    }
    return (
        <Box mt={{ xs: 4, sm: 10 }}>
            <Container component="main">
                {!!maintainer && logo[maintainer]}

                <Box mt={{ xs: 8, md: 16 }} position="relative">
                    {hasShowBackgroundLogo && (
                        <Box
                            zIndex={-1}
                            position="absolute"
                            top={0}
                            right={0}
                            {...testID('logo-background')}
                        >
                            <LogoBackground fill={'#0050F0'} />
                        </Box>
                    )}

                    {children}
                </Box>

                <Divider {...testID('divider')} />

                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    justifyContent="space-between"
                    mt={{ xs: 2, sm: 4 }}
                    mb={{ xs: 4, sm: 8 }}
                >
                    <Box display="flex" alignItems="center" mb={{ xs: 1 }}>
                        <SecurityIcon color="primary" />
                        <Box mr={1} />
                        <Typography style={{ fontWeight: 500 }}>
                            Protegido por:
                        </Typography>
                    </Box>

                    <Box display="flex" alignItems="center">
                        <POSImageWithFallback
                            src={logoPciWebp}
                            fallback={logoPciPng}
                            width={isSm ? 122 : 85}
                        />
                        <Box mr={{ xs: 4, md: 8 }} />
                        <POSImageWithFallback
                            src={logoVindiWebp}
                            fallback={logoVindiPng}
                            width={isSm ? 123 : 88}
                        />
                        <Box ml={{ xs: 4, md: 8 }} />
                        <POSImageWithFallback
                            src={logoAmazonWebp}
                            fallback={logoAmazonPng}
                            width={isSm ? 125 : 91}
                        />
                    </Box>
                </Box>
            </Container>
            <POSFooter />
        </Box>
    )
}
