import React, { useState } from 'react'
import clsx from 'clsx'

import {
    TextFieldProps,
    InputAdornment,
    LinearProgress,
    Typography,
    Box,
    Button,
    styled,
} from '@material-ui/core'

import { testID } from 'src/config/test'

import { TextFieldCustom } from './style'

export type POSCouponProps = {
    defaultValue?: string
    status?: 'success' | 'error'
    isLoading?: boolean
    onSend: (coupon: string) => void
} & TextFieldProps

const CouponButton = styled(Button)(({ theme }) => ({
    fontWeight: 'bold',
    '&:disabled': {
        backgroundColor: theme.palette.action.disabled,
        color: theme.palette.common.white,
    },
}))

export const POSCoupon = (props: POSCouponProps) => {
    const { status, isLoading, onSend, defaultValue, ...rest } = props

    const [value, setValue] = useState<string>(defaultValue || '')

    const handleClick = () => {
        onSend(value)
    }

    const handlekeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleClick()
        }
    }

    return (
        <Box id="coupon">
            <Box position="relative">
                {/* @ts-ignore */}
                <TextFieldCustom
                    value={value}
                    className={clsx(status === 'success' && 'success')}
                    error={status === 'error'}
                    variant="filled"
                    fullWidth
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <CouponButton
                                    onClick={handleClick}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={!value.length}
                                >
                                    Aplicar
                                </CouponButton>
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{
                        ...testID('input-coupon'),
                    }}
                    style={{ opacity: isLoading ? 0.24 : 1 }}
                    onKeyPress={handlekeyPress}
                    onChange={(event) => setValue(event.target.value)}
                    placeholder="Código de indicação"
                    {...rest}
                />

                {isLoading && (
                    <Box
                        position="absolute"
                        right={0}
                        left={0}
                        top={20}
                        bottom={0}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <LinearProgress />
                        <Box mt={1} />
                        <Typography
                            color="primary"
                            variant="caption"
                            align="center"
                            style={{ fontWeight: 500 }}
                        >
                            Validando Cupom
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    )
}
