import axios from 'axios'

import { env } from 'src/config/enviroments'

export const axiosInstance = axios.create({
    baseURL: env.API_URL,
})

export const posCheckoutBffClient = axios.create({
    baseURL: env.CHECKOUT_BFF_URL,
})
