import { Box, Typography, Grid } from '@material-ui/core'

import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { cpf } from 'cpf-cnpj-validator'

import { POSCard, POSFieldFormik, POSFieldRecaptcha } from 'src/components'

import { testID } from 'src/config/test'

import { FooterForm } from './FooterForm'
import BillingDayContainer from './components/BillingDayContainer'

const initialValues = {
    email: '',
    cpf: '',
    name: '',
    recaptcha: '',
    billingDay: '',
}

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('E-mail é obrigatório')
        .email('Insira um e-mail válido')
        .matches(
            /^([\w\S]{2,})+@([\w-]+\.)+[\w-]{2,4}$/g,
            'Insira um e-mail válido'
        ),
    cpf: Yup.string()
        .required('CPF é obrigatório')
        .min(11, 'Insira um número com 11 dígitos')
        .test('cpf', 'CPF Inválido', (value) => cpf.isValid(value)),
    name: Yup.string()
        .required('Nome é obrigatório')
        .matches(
            /^[a-z\u00C0-\u00FF\s]*$/gi,
            'Seu nome não pode conter números ou símbolos'
        )
        .matches(/[a-z]+\s+[a-z]+/gi, 'Insira o seu nome completo'),
    recaptcha: Yup.string().required('Campo obrigatório').nullable(),
    showBillingDay: Yup.boolean(),
    billingDay: Yup.string().when('showBillingDay', {
        is: true,
        then: Yup.string().required('Dia para cobrança é obrigatório'),
        otherwise: Yup.string().notRequired(),
    })
})

export type BankSlipFormValues = Partial<typeof initialValues>

export type SubmitBankSlip = (
    values: BankSlipFormValues,
    formik: FormikHelpers<BankSlipFormValues>
) => void

type BankSlipProps = {
    disabled?: boolean
    onSubmit: SubmitBankSlip
    onBack: () => void
    initialValues: BankSlipFormValues
    availableBillingDays?: number[]
}

export const BankSlip = ({
    disabled,
    onSubmit,
    onBack,
    initialValues: initialValuesProp,
    availableBillingDays,
}: BankSlipProps) => {
    const opacityProp = disabled ? { opacity: '0.2' } : {}

    return (
        <POSCard
            p={2}
            wrapperProps={{ position: 'relative', style: { ...opacityProp } }}
        >
            <Box
                mb={4}
                display="flex"
                alignItems={{ xs: 'baseline', sm: 'center' }}
                justifyContent="space-between"
                flexDirection={{ xs: 'column', sm: 'row' }}
            >
                <Box order={{ xs: 1, sm: 0 }}>
                    <Typography variant="body1" color="textSecondary">
                        Complete as informações que irão constar no boleto:
                    </Typography>
                </Box>
            </Box>

            <Formik
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                initialValues={
                    { ...initialValues, ...initialValuesProp, showBillingDay: !!availableBillingDays } as any
                }
            >
                {({ isSubmitting}) => {
                    return (
                        <Form>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <POSFieldFormik
                                        required
                                        name="email"
                                        label="E-mail:"
                                        placeholder="exemplo@sanar.com"
                                        disabled={disabled}
                                        inputProps={{
                                            ...testID('email'),
                                            maxLength: 100,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <POSFieldFormik
                                        required
                                        name="cpf"
                                        label="CPF:"
                                        placeholder="000.000.000-00"
                                        disabled={disabled}
                                        fieldType="cpf"
                                        inputProps={{
                                            ...testID('cpf'),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <POSFieldFormik
                                        required
                                        name="name"
                                        label="Nome completo:"
                                        placeholder="Nome completo"
                                        disabled={disabled}
                                        inputProps={{
                                            ...testID('name'),
                                            maxLength: 150,
                                        }}
                                    />
                                </Grid>
                                {availableBillingDays && (
                                    <BillingDayContainer availableBillingDays={availableBillingDays} initialValue={initialValues.billingDay} disabled={disabled} />
                                   )}
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <POSFieldRecaptcha
                                        name="recaptcha"
                                        disabled={disabled}
                                    />
                                </Grid>
                                <FooterForm
                                    onBack={onBack}
                                    isSubmitting={isSubmitting}
                                    disabled={disabled}
                                />
                            </Grid>
                        </Form>
                    )}
                }
            </Formik>
        </POSCard>
    )
}
