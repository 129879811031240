import { Box, Typography, StepIconProps } from '@material-ui/core'

import Check from '@material-ui/icons/Check'

import { testID } from 'src/config/test'

export type POSStepIconProps = StepIconProps

const commonProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderRadius: 12,
} as any

export const IconNumber = ({ icon, active, completed }: StepIconProps) => (
    <Box
        bgcolor="common.white"
        borderColor="primary.main"
        border="2px solid"
        style={{ opacity: !active && !completed ? 0.24 : 1 }}
        {...testID('icon-number')}
        {...commonProps}
    >
        <Typography
            color="primary"
            variant="caption"
            style={{ fontWeight: 500 }}
        >
            {icon}
        </Typography>
    </Box>
)

export const POSStepIcon = (props: POSStepIconProps) => {
    const { completed } = props

    return (
        <Box>
            {completed ? (
                <Box
                    bgcolor="primary.main"
                    {...commonProps}
                    {...testID('icon-check')}
                >
                    <Check fontSize="small" style={{ color: '#ffffff' }} />
                </Box>
            ) : (
                <IconNumber {...props} />
            )}
        </Box>
    )
}
