export const INPUT_COUNTRY_CODE_MASK = [
    '+',
    /^[0-9-]+$/,
    /^[0-9-]+$/,
    /^[0-9-]+$/,
    /^[0-9-]+$/,
    /^[0-9-]+$/,
    /^[0-9-]+$/,
    /^[0-9-]+$/,
]
