import { useEffect } from 'react'

import { useSnackbar } from 'notistack'

import { useMainContext } from 'src/hooks'

export type VerifyRouteAccessProps = {
    condition: boolean
    isContractPage?: boolean
}

export const POSVerifyRouteAccess = ({
    condition,
    isContractPage = false,
}: VerifyRouteAccessProps) => {
    const { enqueueSnackbar } = useSnackbar()
    const { goToBasicData, goToContractPage } = useMainContext()

    useEffect(() => {
        if (condition) {
            enqueueSnackbar(
                `Ainda faltam algumas informações a serem preenchidas.
                 Você será redirecionado para preencher essas informações.`,
                {
                    variant: 'warning',
                    style: { whiteSpace: 'pre-line' },
                }
            )

            setTimeout(isContractPage ? goToContractPage : goToBasicData, 5000)
        }
    }, [
        condition,
        enqueueSnackbar,
        goToBasicData,
        goToContractPage,
        isContractPage,
    ])

    return null
}
