import React, { useState } from 'react'
import { FieldConfig, GenericFieldHTMLAttributes, Field } from 'formik'
import {
    TextFieldProps,
    InputLabel,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core'
import { TextField } from 'formik-material-ui'

export type POSFieldRGProps = GenericFieldHTMLAttributes &
    FieldConfig &
    TextFieldProps

export const POSFieldRG = ({ label, ...props }: POSFieldRGProps) => {
    const [isNotForeigner, setIsNotForeigner] = useState(false)

    return (
        <>
            <InputLabel>{label}</InputLabel>
            <Field
                required={!isNotForeigner}
                component={TextField}
                variant="filled"
                fullWidth
                {...props}
            />
            <FormControlLabel
                label="Não possuo RG, sou estrangeiro(a)"
                control={
                    <Checkbox
                        checked={isNotForeigner}
                        onChange={() => setIsNotForeigner(!isNotForeigner)}
                    />
                }
            />
        </>
    )
}
