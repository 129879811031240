import { Link, Box, Typography } from '@material-ui/core'

const fontWeight = 500

const commonProps = {
    style: { fontWeight },
    underline: 'always',
    color: 'inherit',
    target: '_blank',
    rel: 'noreferrer',
} as any

export const getCourseUrl = (course = '') =>
    `https://pos.sanarmed.com/${course}/?utm_source=lpgeral&utm_medium=home&utm_campaign=inscrevase`

export const Courses = () => (
    <Box mr={{ xs: 0, md: 4, lg: 10 }} mb={{ xs: 5, sm: 0 }}>
        <Typography paragraph style={{ fontWeight: 700 }}>
            Cursos
        </Typography>
        <Box>
            <Box mb={1}>
                <Link
                    {...commonProps}
                    href={getCourseUrl('posemmedicinadeemergencia')}
                >
                    Medicina de Emergência
                </Link>
            </Box>
            <Box mb={1}>
                <Link {...commonProps} href={getCourseUrl('psiquiatria')}>
                    Psiquiatria
                </Link>
            </Box>
            <Box mb={1}>
                <Link
                    {...commonProps}
                    href={getCourseUrl('medicinadefamiliaecomunidade')}
                >
                    Medicina de Família e Comunidade
                </Link>
            </Box>
            <Box mb={1}>
                <Link {...commonProps} href={getCourseUrl('geriatria')}>
                    Geriatria
                </Link>
            </Box>
            <Box mb={1}>
                <Link {...commonProps} href={getCourseUrl('endocrinologia')}>
                    Endocrinologia
                </Link>
            </Box>
            <Box mb={1}>
                <Link {...commonProps} href={getCourseUrl('terapiaintensiva')}>
                    Terapia Intensiva
                </Link>
            </Box>
            <Box mb={1}>
                <Link {...commonProps} href={getCourseUrl('nutrologia')}>
                    Nutrologia
                </Link>
            </Box>
            <Box mb={1}>
                <Link {...commonProps} href={getCourseUrl('dermatologia')}>
                    Dermatologia
                </Link>
            </Box>
            <Box mb={1}>
                <Link
                    {...commonProps}
                    href={getCourseUrl('medicina-do-esporte')}
                >
                    Medicina do Esporte
                </Link>
            </Box>
            <Box mb={1}>
                <Link
                    {...commonProps}
                    href={getCourseUrl('cuidados-paliativos')}
                >
                    Cuidados Paliativos
                </Link>
            </Box>
        </Box>
    </Box>
)
