import { Typography, Grid, Button, Box } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { POSCard, POSFieldFormik } from 'src/components'

import { testID } from 'src/config/test'
import { ClipboardEvent } from 'react'
import { countryCode } from 'src/utils/country-code-validator'

const COUNTRY_CODE_BR = '+55'

const initialValues = {
    name: '',
    email: '',
    phone: '',
    emailConfirmation: '',
    countryCode: COUNTRY_CODE_BR,
}

export type IdentificationFormValues = Partial<typeof initialValues>

export type SubmitIdentification = (
    values: IdentificationFormValues,
    formik: FormikHelpers<IdentificationFormValues>
) => void

type IdentificationProps = {
    isRight?: boolean
    onSubmit: SubmitIdentification
    initialValues: IdentificationFormValues
}

const phoneNumberRegex = new RegExp(
    `((\\(?[1,2,3,4,5,6,7,8,9]{2}\\)\\s)([9])(\\d{4}-\\d{4}))`
)

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nome é obrigatório')
        .matches(
            /^[a-z\u00C0-\u00FF\s]*$/gi,
            'Seu nome não pode conter números ou símbolos'
        )
        .matches(/[a-z]+\s+[a-z]+/gi, 'Insira o seu nome completo'),
    email: Yup.string()
        .required('E-mail é obrigatório')
        .email('Insira um e-mail válido')
        .matches(
            /^([\w\S]{2,})+@([\w-]+\.)+[\w-]{2,4}$/g,
            'Insira um e-mail válido'
        ),
    emailConfirmation: Yup.string()
        .required('Confirmação de e-mail é obrigatório')
        .oneOf(
            [Yup.ref('email'), null],
            'Você precisa utilizar o mesmo e-mail'
        ),
    countryCode: Yup.string()
        .required('Código do país é obrigatório')
        .oneOf(countryCode, 'Informe um código válido'),
    phone: Yup.string()
        .required('Telefone é obrigatório')
        .when('countryCode', {
            is: (value: string) => value === COUNTRY_CODE_BR,
            then: Yup.string().matches(
                phoneNumberRegex,
                'Informe um telefone válido'
            ),
        }),
})

export const Identification = ({
    isRight,
    onSubmit,
    initialValues: initialValuesProps,
}: IdentificationProps) => {
    const opacityProp = isRight ? { opacity: '0.2' } : {}

    return (
        <Box position="relative">
            {isRight && (
                <Box
                    position="absolute"
                    width="100%"
                    height="100%"
                    top={0}
                    left={0}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    {...testID('is-right')}
                >
                    <CheckCircleIcon
                        color="primary"
                        style={{ fontSize: '5rem' }}
                    />
                    <Typography color="primary" style={{ fontWeight: 700 }}>
                        Salvo!
                    </Typography>
                </Box>
            )}

            <POSCard
                p={2}
                wrapperProps={{
                    style: { ...opacityProp },
                }}
            >
                <Typography paragraph>Informe seus dados pessoais:</Typography>
                <Box mb={4} />
                <Formik
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    initialValues={
                        { ...initialValues, ...initialValuesProps } as any
                    }
                >
                    {({ isSubmitting, values }) => {
                        return (
                            <Form>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <POSFieldFormik
                                            required
                                            name="name"
                                            label="Seu nome:"
                                            placeholder="Nome completo"
                                            disabled={isRight}
                                            inputProps={{
                                                ...testID('name'),
                                                maxLength: 150,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <POSFieldFormik
                                            required
                                            name="email"
                                            label="Seu e-mail:"
                                            placeholder="exemplo@sanar.com"
                                            disabled={isRight}
                                            inputProps={{
                                                ...testID('email'),
                                                maxLength: 100,
                                                inputMode: 'email',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <POSFieldFormik
                                            required
                                            name="emailConfirmation"
                                            label="Confirme seu e-mail:"
                                            placeholder="exemplo@sanar.com"
                                            disabled={isRight}
                                            onPaste={(
                                                e: ClipboardEvent<HTMLInputElement>
                                            ) => {
                                                e.preventDefault()
                                                return false
                                            }}
                                            inputProps={{
                                                ...testID('email-confirmation'),
                                                maxLength: 100,
                                                inputMode: 'email',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={4}>
                                        <POSFieldFormik
                                            required
                                            name="countryCode"
                                            label="Código do país:"
                                            fieldType="countryCode"
                                            placeholder="+55"
                                            value={values.countryCode}
                                            defaultValue={
                                                initialValues.countryCode
                                            }
                                            disabled={isRight}
                                            inputProps={{
                                                ...testID('country-code'),
                                                maxLength: 8,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={7} sm={8}>
                                        <POSFieldFormik
                                            required
                                            name="phone"
                                            label="Seu telefone celular:"
                                            placeholder={
                                                values.countryCode ===
                                                COUNTRY_CODE_BR
                                                    ? `(00) 00000-0000`
                                                    : 'Telefone de seu país'
                                            }
                                            enableMask={
                                                values.countryCode ===
                                                COUNTRY_CODE_BR
                                            }
                                            fieldType="phone"
                                            disabled={isRight}
                                            inputProps={{
                                                ...testID('phone'),
                                                maxLength: 18,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            disabled={isRight || isSubmitting}
                                        >
                                            Salvar e continuar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </POSCard>
        </Box>
    )
}
