import { useMutation, UseMutationOptions } from 'react-query'

import { axiosInstance } from 'src/config/data-access/axios'

interface ICustomer {
    email: string
    phone?: string
    whatsAppAccept?: boolean
}

export const useUpdateCustomer = (
    options?: UseMutationOptions<any, any, any, any>
) => {
    return useMutation<any, any, ICustomer>((customer) => {
        const { email, ...rest } = customer
        return axiosInstance
            .put(`/v1/customer/${email}`, rest)
            .then(({ data }) => data)
    }, options)
}
