import { Box, Typography, Grid, MenuItem, makeStyles } from '@material-ui/core'

import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import cardValidator from 'card-validator'
import { cpf } from 'cpf-cnpj-validator'

import { ReactComponent as MastercardFlag } from 'src/assets/images/mastercard.svg'
import { ReactComponent as EloFlag } from 'src/assets/images/elo.svg'
import { ReactComponent as VisaFlag } from 'src/assets/images/visa.svg'
import { ReactComponent as HipercardFlag } from 'src/assets/images/hipercard.svg'

import { POSCard, POSFieldFormik, POSFieldRecaptcha } from 'src/components'

import { testID } from 'src/config/test'

import { FooterForm } from './FooterForm'
import { cardBrandList } from 'src/utils/card-brand-list'
import BillingDayContainer from './components/BillingDayContainer'

const initialValues = {
    creditCard: '',
    creditCardBrand: '',
    name: '',
    expirationDate: '',
    cvv: '',
    recaptcha: '',
    cpf: '',
    billingDay: '',
}

const removeUnderline = (value = '') => value.replace(/_/g, '')

const validationSchema = Yup.object().shape({
    creditCard: Yup.string()
        .required('Número do cartão é obrigatório')
        .test('credit-card', 'Cartão inválido', (value = '') => {
            const formatted = removeUnderline(value)
            return cardValidator.number(formatted).isValid
        }),
    name: Yup.string()
        .required('Nome do titular é obrigatório')
        .matches(/^[a-z\s]*$/gi, 'Seu nome não pode conter números ou símbolos')
        .matches(/[a-z]+\s+[a-z]+/gi, 'Insira o nome como está no cartão')
        .test('name', 'Nome do titular inválido', (value = '') => {
            return cardValidator.cardholderName(value).isValid
        }),
    expirationDate: Yup.string()
        .required('Vencimento é obrigatório')
        .test('expiration-date', 'Data inválida', (value = '') => {
            const formatted = removeUnderline(value)
            return cardValidator.expirationDate(formatted).isValid
        }),
    cvv: Yup.string()
        .required('Código de segurança é obrigatório')
        .test('cvv-length', 'Mínimo 3 dígitos', (value = '') => {
            const formatted = removeUnderline(value)
            return formatted?.length >= 3 && formatted?.length <= 4
        }),
    cpf: Yup.string()
        .required('CPF é obrigatório')
        .min(11, 'Insira um número com 11 dígitos')
        .test('cpf', 'CPF Inválido', (value) => cpf.isValid(value)),
    recaptcha: Yup.string().required('Campo obrigatório').nullable(),
    creditCardBrand: Yup.string().required('Bandeira do cartão é obrigatório'),
    showBillingDay: Yup.boolean(),
    billingDay: Yup.string().when('showBillingDay', {
        is: true,
        then: Yup.string().required('Dia para cobrança é obrigatório'),
        otherwise: Yup.string().notRequired(),
    })
})

export type CreditCardFormValues = Partial<typeof initialValues>

export type SubmitCreditCard = (
    values: CreditCardFormValues,
    formik: FormikHelpers<CreditCardFormValues>
) => void

type CreditCardProps = {
    disabled?: boolean
    onSubmit: SubmitCreditCard
    onBack: () => void
    initialValues: CreditCardFormValues
    availableBillingDays?: number[]
}

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        maxHeight: 250,
    },
}))

export const CreditCard = ({
    disabled,
    onSubmit,
    onBack,
    initialValues: initialValuesProp,
    availableBillingDays
}: CreditCardProps) => {
    const classes = useStyles()
    const opacityProp = disabled ? { opacity: '0.2' } : {}

    return (
        <POSCard
            p={2}
            wrapperProps={{ position: 'relative', style: { ...opacityProp } }}
        >
            <Box
                mb={4}
                display="flex"
                alignItems={{ xs: 'baseline', sm: 'center' }}
                justifyContent="space-between"
                flexDirection={{ xs: 'column', sm: 'row' }}
            >
                <Box order={{ xs: 1, sm: 0 }}>
                    <Typography variant="body1" color="textSecondary">
                        Informe os dados do seu cartão:
                    </Typography>
                </Box>

                <Box mb={{ xs: 2, sm: 0 }} order={{ xs: 0, sm: 1 }}>
                    <MastercardFlag style={{ marginRight: '8px' }} />
                    <VisaFlag style={{ marginRight: '8px' }} />
                    <EloFlag style={{ marginRight: '8px' }} />
                    <HipercardFlag style={{ marginRight: '8px' }} />
                </Box>
            </Box>

            <Formik
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                initialValues={
                    { ...initialValues, ...initialValuesProp, showBillingDay: !!availableBillingDays } as any
                }
            >
                {({ isSubmitting }) => {
                    return (
                           <Form>
                               <Grid container spacing={4}>
                                   <Grid item xs={12} sm={6}>
                                       <POSFieldFormik
                                           required
                                           select
                                           name="creditCardBrand"
                                           label="Bandeira do cartão:"
                                           disabled={disabled}
                                           SelectProps={{
                                               MenuProps: {
                                                   classes: {
                                                       paper: classes.menuPaper,
                                                   },
                                               },
                                           }}
                                           inputProps={{
                                               ...testID('card-brand'),
                                           }}
                                       >
                                           {cardBrandList.map(({ value, name }) => (
                                               <MenuItem value={value} key={value}>
                                                   {name}
                                               </MenuItem>
                                           ))}
                                       </POSFieldFormik>
                                   </Grid>
                                   <Grid item xs={12} sm={6}>
                                       <POSFieldFormik
                                           required
                                           name="creditCard"
                                           label="Número do cartão:"
                                           placeholder="0000.0000.0000.0000"
                                           fieldType="creditCard"
                                           disabled={disabled}
                                           inputProps={{
                                               ...testID('creditCard'),
                                           }}
                                       />
                                   </Grid>
                                   <Grid item xs={12} sm={6}>
                                       <POSFieldFormik
                                           required
                                           name="name"
                                           label="Nome do titular:"
                                           placeholder="Nome completo"
                                           disabled={disabled}
                                           inputProps={{
                                               ...testID('name'),
                                               maxLength: 255,
                                           }}
                                       />
                                   </Grid>
                                   <Grid item xs={12} sm={6}>
                                       <POSFieldFormik
                                           required
                                           name="cpf"
                                           label="CPF:"
                                           placeholder="000.000.000-00"
                                           disabled={disabled}
                                           fieldType="cpf"
                                           inputProps={{
                                               ...testID('cpf'),
                                           }}
                                       />
                                   </Grid>
                                   <Grid item xs={12} sm={3}>
                                       <POSFieldFormik
                                           required
                                           name="expirationDate"
                                           label="Vencimento:"
                                           placeholder="MM/AA"
                                           fieldType="expirationDate"
                                           disabled={disabled}
                                           inputProps={{
                                               ...testID('expirationDate'),
                                           }}
                                       />
                                   </Grid>
                                   <Grid item xs={12} sm={3}>
                                       <POSFieldFormik
                                           required
                                           name="cvv"
                                           label="Código de segurança:"
                                           placeholder="000"
                                           fieldType="cvv"
                                           disabled={disabled}
                                           inputProps={{
                                               ...testID('cvv'),
                                           }}
                                       />
                                   </Grid>
       
                                   {availableBillingDays && (
                                    <BillingDayContainer availableBillingDays={availableBillingDays} initialValue={initialValues.billingDay} disabled={disabled} />
                                   )}
       
                                   <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                       <POSFieldRecaptcha
                                           name="recaptcha"
                                           disabled={disabled}
                                       />
                                   </Grid>
                                   <FooterForm
                                       onBack={onBack}
                                       isSubmitting={isSubmitting}
                                       disabled={disabled}
                                   />
                               </Grid>
                           </Form>
                       )
                    }
                }
            </Formik>
        </POSCard>
    )
}
