import { ICourse, IPlan, PaymentMethodType } from 'src/types'

import { IProductData, ICourseData } from './Main'

export const getCurrentSelectedProduct = (
    planValue: string,
    plans: IProductData[] = []
) => plans.find((plan) => plan?.uniqueId === planValue)

export const getUniqueProduct = (product: IProductData) =>
    product?.isPrimary &&
    product?.paymentMethod === PaymentMethodType.CREDIT_CARD

const createProductData = (
    plans: IPlan[],
    isBankSlipEnabled: boolean
): IProductData[] => {
    const activePaymentMethod = isBankSlipEnabled
        ? PaymentMethodType.BANK_SLIP
        : PaymentMethodType.CREDIT_CARD

    const products: IProductData[] = []

    for (const plan of plans) {
        const productInfo = plan.paymentMethodInfo.filter((payment) => {
            return payment.type === activePaymentMethod
        })

        const baseProduct = {
            numberOfCyles: plan.numberOfCycles,
            cycleInMonths: plan.cycleInMonths,
            installments: plan.numberOfCycles,
            totalInstallments: plan.cycleInMonths * plan.numberOfCycles,
            isPrimary: plan.isPrimary,
            price: plan.price,
            planSlug: plan.slug,
            finalFullPrice: plan.price,
            createdDate: Date().toString(),
            id: 0,
        }

        for (const product of productInfo) {
            const productDescription = (installments: number) => {
                if (activePaymentMethod === PaymentMethodType.CREDIT_CARD) {
                    if (installments > 1) {
                        return `Cartão de Crédito (em até ${installments}x)`
                    }
                    return 'Cartão de Crédito (à vista)'
                } else {
                    if (installments > 1) {
                        return `Boleto (em até ${installments}x)`
                    }
                    return 'Boleto (à vista)'
                }
            }

            const containsFirstCharge = ['1x-9900-11x', '1x-9900-23x'].some(
                (campagnPlan) => plan.slug.includes(campagnPlan)
            )

            const firstParcelReductionValue = 99.0

            products.push({
                ...baseProduct,
                discountsPercentageTotal: product.discountPercentage,
                discountTotalValue: product.discountTotalValue,
                installmentPrice: product.installmentPrice,
                cycleTotalPrice: product.cycleTotalPrice,
                uniqueId: `${plan.slug}:${activePaymentMethod}`,
                customDescription: productDescription(plan.numberOfCycles),
                priceFirstInstallment: containsFirstCharge
                    ? firstParcelReductionValue
                    : product.promo?.installmentValue,
                description: productDescription(plan.numberOfCycles),
                paymentMethod: activePaymentMethod,
                totalValueToPay: containsFirstCharge
                    ? product.totalValueToPay + firstParcelReductionValue
                    : product.totalValueToPay,
                availableBillingDays: product.availableBillingDays,
                isFirstChargeReductionCampaign: containsFirstCharge,
            })
        }
    }

    const result: IProductData[] = [...products].sort(
        (a, b) => a.totalInstallments - b.totalInstallments
    )

    return result
}

export const makeCourse = (
    data: ICourse,
    isBankSlipEnabled: boolean
): ICourseData => {
    return {
        ...data,
        duration: data?.durationInMonths,
        products: createProductData(data.plans as IPlan[], isBankSlipEnabled),
    }
}

export const goScrollTop = () => window.scrollTo(0, 0)
