export const paths = {
    success: 'sucesso',
    error: 'erro',
    'not-found': 'nao-encontrado',
    'personal-data': 'dados-pessoais',
    payment: 'pagamento',
    review: 'revisar-informacoes',
    initial: '',
    contractRegularization: 'contrato',
    contractRegularizationConfirmation: 'contrato-confirmacao',
}
