import React, { useEffect } from 'react'

import { Box, useMediaQuery, useTheme } from '@material-ui/core'

import {
    useMainContext,
    useQuery,
    useUpdateCustomer,
    useTrackerContext,
} from 'src/hooks'

import { formatCurrency, formatStartDate, onlyNumbers } from 'src/utils'

import { SubmitWhatsAppAccept } from 'src/components/whatsapp-accept/WhatsAppAccept'
import { ConfimedOrder } from './ConfimedOrder'
import { NextStep } from './NextStep'
import { SuccessText } from 'src/components/'
import { useHistory } from 'react-router-dom'
import comboHelper from '../../utils/combo-helper'

const Success = () => {
    const history = useHistory()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    const query = useQuery()

    const { mutateAsync: updateCustomer } = useUpdateCustomer()

    const barCode = query.get('code') || ''
    const bankSlipUrl = query.get('url') || ''

    const {
        buyer,
        data,
        productSelected,
        comboVariant,
        comboFlag,
        setProductSelectValue,
        goToRoute,
    } = useMainContext()

    const startDate =
        data?.courseClasses?.[0]?.startDate &&
        formatStartDate(data.courseClasses[0].startDate)

    const valueFirstInstallmentFormatted = formatCurrency.format(
        productSelected?.priceFirstInstallment || 0
    )

    const { track } = useTrackerContext()

    useEffect(() => {
        if (!!data?.id) {
            track({
                eventName: 'Purchase completed',
                additionalAttrs: {
                    'Payment method': buyer?.paymentMethod,
                    'Course name': data?.name,
                    'Course duration': `${data?.duration} meses`,
                    Cupom: data?.coupon?.promoCode,
                },
            })
        }
    }, [
        track,
        data?.name,
        data?.id,
        data?.duration,
        buyer?.paymentMethod,
        data?.coupon?.promoCode,
    ])

    const handleSubmitCustomer: SubmitWhatsAppAccept = (values) => {
        return updateCustomer({
            phone: values?.phone
                ? `${values.countryCode} ${onlyNumbers(values?.phone)}`
                : undefined,
            whatsAppAccept: values?.acceptWhatsapp,
            email: buyer?.email!,
        })
    }

    const next = comboVariant.find((combo) =>
        history.location.pathname.includes(combo?.current?.slug)
    )?.next

    const isCurrentCourseInCombo = comboHelper(
        comboVariant
    ).uniqueCourses?.some((course) =>
        history.location.pathname.includes(course)
    )

    const goToComboCheckout = () => {
        setProductSelectValue('')
        goToRoute(`/${next?.slug}/`)
    }

    return (
        <Box mb={{ xs: 8, md: 15 }}>
            <SuccessText
                buyer={buyer}
                matches={matches}
                onSubmit={handleSubmitCustomer}
                title="Estamos processando seu método de pagamento e em breve você estará matriculado."
                subtitle="Confira abaixo o status do seu pedido e os próximos passos. Aproveite também e habilite seu WhatsApp para já começar a receber informações sobre o seu curso."
                shouldShowComboBanner={comboFlag && isCurrentCourseInCombo}
                goToComboCheckout={goToComboCheckout}
                next={next}
            />

            <ConfimedOrder
                barCode={barCode}
                bankSlipUrl={bankSlipUrl}
                matches={matches}
                name={data?.name}
                duration={data?.duration || 0}
                startDate={startDate}
                praticalWorkload={data?.practicalWorkload}
                eadWorkload={data?.eadWorkload || 0}
                value={formatCurrency.format(
                    productSelected?.installmentPrice!
                )}
                valueFirstInstallmentFormatted={valueFirstInstallmentFormatted}
            />

            <NextStep matches={matches} startDate={startDate} />
        </Box>
    )
}

export default Success
