import BwModelicaMediumWoff from 'src/assets/fonts/BwModelica-Medium.woff'
import BwModelicaRegularWoff from 'src/assets/fonts/BwModelica-Regular.woff'
import BwModelicaBoldWoff from 'src/assets/fonts/BwModelica-Bold.woff'

export const regular = {
    fontFamily: 'Bw Modelica',
    fontWeight: 400,
    src: `
      url(${BwModelicaRegularWoff}) format('woff')
    `,
}

export const medium = {
    fontFamily: 'Bw Modelica',
    fontWeight: 500,
    src: `
    url(${BwModelicaMediumWoff}) format('woff')
  `,
}

export const bold = {
    fontFamily: 'Bw Modelica',
    fontWeight: 700,
    src: `
      url(${BwModelicaBoldWoff}) format('woff')
    `,
}
