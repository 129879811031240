import { Box, Typography } from '@material-ui/core';
import { ReactComponent as CalendarIcon } from 'src/assets/images/calendar.svg'

type Props = { 
    key: number;
    description: string;
    testID: string
}

const InstallmentItem = ({ key, description, testID }: Props) => {
  return (
    <Box
    display='grid'
    justifyItems='center'
    key={`${testID}-${key}`}
>
    <CalendarIcon />
    <Typography
        style={{
            color: '#002266',
            fontSize: '0.75rem',
            fontWeight: 700,
        }}
    >
       {description}
    </Typography>
</Box>
  )
}

export default InstallmentItem