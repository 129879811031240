import { useMemo, useEffect } from 'react'
import {
    Box,
    Typography,
    Divider,
    Button,
    useMediaQuery,
    useTheme,
    Grid,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import { POSCard } from 'src/components'

import { ReactComponent as LogoSymbol } from 'src/assets/images/logo-symbol.svg'
import { ReactComponent as LogoBlackFriday } from 'src/assets/images/logo-black-sanar.svg'
import { ReactComponent as LogoBackground } from 'src/assets/images/logo-background.svg'

import { useMainContext, useTrackerContext } from 'src/hooks'

import { Price } from './Price'
import { Details } from './Details'
import { SkeletonInitial } from './Skeleton'
import { endOfDay, format } from 'date-fns'
import { formatCurrency } from 'src/utils'

const getDividerOrientation = (matches: boolean) => {
    if (matches) {
        return 'horizontal'
    }
    return 'vertical'
}

const getCourseCategory = (practicalWorkload = 0) =>
    practicalWorkload > 0 ? 'Hibrido' : 'EAD'

const Initial = () => {
    const {
        data,
        primaryProduct,
        isLoading,
        goToBasicData,
        isPromoEnabled,
        isBankSlipEnabled,
        isRecurrenceEnabled,
        isBlackFridayEnabled,
    } = useMainContext()

    const { track } = useTrackerContext()

    useEffect(() => {
        if (!!data?.id) {
            track({
                eventName: 'Course summary page viewed',
                additionalAttrs: {
                    'Course name': data?.name,
                    'Course duration': `${data?.durationInMonths} meses`,
                    'Course category': getCourseCategory(
                        data?.practicalWorkload
                    ),
                    'Course ID': data?.id,
                },
            })
        }
    }, [
        track,
        data?.name,
        data?.id,
        data?.durationInMonths,
        data?.practicalWorkload,
    ])
    const hasCoupon = !!data?.coupon

    const currentDate =
        format(endOfDay(new Date()), 'yyyy-MM-dd') + 'T23:59:59.999Z'

    const immediateStart = currentDate > data?.courseClasses?.[0]?.startDate

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    const couponPercentage = data?.coupon?.percentage

    const badgeTitle = useMemo(() => {
        if (!isBankSlipEnabled && isPromoEnabled && !isRecurrenceEnabled) {
            return `Apenas ${formatCurrency.format(
                primaryProduct?.priceFirstInstallment || 0
            )} no primeiro mês!!! 😱`
        }

        if (immediateStart && hasCoupon) {
            return `${couponPercentage}% de desconto. Compre agora e já comece a estudar! 🚀`
        }

        if (hasCoupon) {
            return `${couponPercentage}% de desconto. Não perca!`
        }

        if (immediateStart) {
            return `Compre agora e já comece a estudar! 🚀`
        }

        return ''
    }, [
        immediateStart,
        hasCoupon,
        couponPercentage,
        isPromoEnabled,
        isRecurrenceEnabled,
        isBankSlipEnabled,
        primaryProduct,
    ])

    const isMd = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <>
            {isBlackFridayEnabled && (
                <>
                    <Box
                        position="absolute"
                        right={'calc(-49.6vw + 50%)'}
                        left={'calc(-50vw + 50%)'}
                        top={isMd ? '-80px' : '-10px'}
                        height={isMd ? '145%' : '109%'}
                        zIndex={-1}
                        style={{ backgroundColor: '#2F2F31' }}
                    />
                    {isMd && (
                        <Box mt={{ xs: 8, md: 16 }} position="relative">
                            <Box
                                zIndex={-1}
                                position="absolute"
                                top={0}
                                right={0}
                            >
                                <LogoBackground
                                    fill={'white'}
                                    data-testid="sanar-background-logo"
                                />
                            </Box>
                        </Box>
                    )}
                </>
            )}
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Box display="inline-flex" alignItems="center" mb={1}>
                        <LogoSymbol
                            fill={
                                isBlackFridayEnabled
                                    ? theme.palette.primary.light
                                    : theme.palette.primary.main
                            }
                        />
                        <Box mr={1} />
                        <Typography
                            variant="h3"
                            style={{
                                fontWeight: 700,
                                color: isBlackFridayEnabled
                                    ? theme.palette.primary.light
                                    : theme.palette.primary.main,
                            }}
                        >
                            Parabéns!
                        </Typography>
                    </Box>

                    <Box maxWidth={473} mb={8}>
                        <Typography
                            variant="h5"
                            style={{
                                fontWeight: 500,
                                color: isBlackFridayEnabled
                                    ? theme.palette.info.light
                                    : theme.palette.text.primary,
                            }}
                        >
                            Você está dando mais um grande passo na sua
                            carreira!
                        </Typography>
                    </Box>
                </Grid>
                {isBlackFridayEnabled && isMd && (
                    <Grid item xs={4}>
                        <Box
                            display={'flex'}
                            justifyContent={'end'}
                            position={'relative'}
                        >
                            <LogoBlackFriday data-testid="black-friday-logo" />
                        </Box>
                    </Grid>
                )}
            </Grid>

            <Box mb={{ xs: 8, md: 25 }} maxWidth={810}>
                <Box mb={6}>
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        style={{
                            fontWeight: 700,
                            color: isBlackFridayEnabled
                                ? '#E0EBFF'
                                : 'secondary',
                        }}
                    >
                        Pós-graduação Online
                    </Typography>
                    <Typography
                        variant="h4"
                        color="primary"
                        style={{
                            fontWeight: 700,
                            color: isBlackFridayEnabled
                                ? theme.palette.primary.light
                                : theme.palette.primary.main,
                        }}
                    >
                        {isLoading ? <Skeleton /> : data?.name}
                    </Typography>
                </Box>

                <POSCard
                    badgeTitle={badgeTitle}
                    badgeProps={{
                        isLoading,
                        left: { xs: 8, md: 32 },
                        ...(hasCoupon && {
                            bgcolor: hasCoupon && 'success.main',
                            style: { textTransform: 'uppercase' },
                        }),

                        ...(!isPromoEnabled && immediateStart
                            ? {
                                  style: {
                                      background: '#E0EBFF',
                                      color: '#072869',
                                  },
                              }
                            : {}),
                    }}
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    px={{ xs: 1, md: 4 }}
                    bgcolor={
                        !isPromoEnabled && immediateStart ? '#072869' : '#fff'
                    }
                >
                    {isLoading ? (
                        <SkeletonInitial />
                    ) : (
                        <>
                            <Price
                                courseName={data?.name}
                                couponPercentage={couponPercentage}
                                price={
                                    isRecurrenceEnabled
                                        ? primaryProduct!.finalFullPrice /
                                          primaryProduct!.cycleInMonths
                                        : primaryProduct?.finalFullPrice
                                }
                                installmentPrice={
                                    primaryProduct?.installmentPrice || 0
                                }
                                priceWithCoupon={
                                    primaryProduct?.installmentPrice
                                }
                                hasDiscount={
                                    (primaryProduct?.discountsPercentageTotal ||
                                        0) > 0
                                }
                                installments={
                                    isRecurrenceEnabled
                                        ? primaryProduct?.totalInstallments
                                        : primaryProduct?.installments
                                }
                                priceFirstInstallment={
                                    primaryProduct?.priceFirstInstallment
                                }
                                immediateStart={immediateStart}
                            />
                            <Box
                                pl={{ xs: 0, md: 2 }}
                                pr={{ xs: 0, md: 5 }}
                                py={{ xs: 2, md: 0 }}
                            >
                                <Divider
                                    orientation={getDividerOrientation(matches)}
                                    light={true}
                                />
                            </Box>

                            <Details
                                matches={matches}
                                duration={data?.duration || 0}
                                startDate={data?.courseClasses?.[0]?.startDate}
                                praticalWorkload={data?.practicalWorkload}
                                eadWorkload={data?.eadWorkload || 0}
                                immediateStart={immediateStart}
                                isPromoEnabled={isPromoEnabled}
                            />
                        </>
                    )}
                </POSCard>

                <Box mt={4} display="flex" justifyContent="flex-end">
                    <Box width={{ xs: '100%', md: 236 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            disabled={isLoading}
                            onClick={goToBasicData}
                        >
                            Próximo passo
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Initial
