import {
    Box,
    Typography,
    useMediaQuery,
    useTheme,
    Button,
} from '@material-ui/core'

import { ReactComponent as LogoSymbol } from 'src/assets/images/logo-symbol.svg'

import { env } from 'src/config/enviroments'

const CourseNotFound = () => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Box mb={{ xs: 8, md: 64 }} maxWidth={670}>
            <Box display="inline-flex" alignItems="center" mb={3}>
                <LogoSymbol />
                <Box mr={1} />
                <Typography
                    color="primary"
                    variant={matches ? 'h4' : 'h3'}
                    style={{ fontWeight: 700 }}
                >
                    Curso não encontrado!
                </Typography>
            </Box>

            <Typography variant={matches ? 'body1' : 'h5'}>
                Infelizmente a página que você tentou acessar não existe.{' '}
                <strong>
                    Acesse nossa home e tente acessar via página do curso.
                </strong>{' '}
                Se o problema persistir, entre em contato conosco!
            </Typography>

            <Box sx={{ maxWidth: '100%' }} marginTop={4} />
            <Box
                mt={4}
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                width={{ xs: '100%', sm: 504 }}
            >
                <Box flex={1}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        fullWidth
                        href={env.HOME_LINK}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Ir para a home
                    </Button>
                </Box>
                <Box ml={{ xs: 0, sm: 3 }} mt={{ xs: 2, sm: 0 }} flex={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        href={env.CONSULTANT_LINK}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Fale conosco
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default CourseNotFound
