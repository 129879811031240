import { FieldConfig, GenericFieldHTMLAttributes, Field } from 'formik'
import { TextFieldProps, InputLabel } from '@material-ui/core'

import { INPUT_CREDIT_CARD_MASK } from 'src/utils/masks'

import { POSTextMask } from 'src/components'

export type POSFieldCreditCardProps = GenericFieldHTMLAttributes &
    FieldConfig &
    TextFieldProps

export const POSFieldCreditCard = ({
    label,
    ...props
}: POSFieldCreditCardProps) => (
    <>
        <InputLabel>{label}</InputLabel>
        <Field
            component={POSTextMask}
            mask={INPUT_CREDIT_CARD_MASK}
            variant="filled"
            fullWidth
            inputProps={{
                inputMode: 'numeric',
            }}
            {...props}
        />
    </>
)
