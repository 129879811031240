export const cardBrandList = [
    {
        name: 'Visa',
        value: 'visa',
    },
    {
        name: 'Mastercard',
        value: 'mastercard',
    },
    {
        name: 'Elo',
        value: 'elo',
    },
    {
        name: 'Hipercard',
        value: 'hipercard',
    },
]
