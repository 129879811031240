import { Box, BoxProps } from '@material-ui/core'

type ImgWithFallbackProps = {
    alt?: string
    src: string
    fallback: string
    type?: string
} & BoxProps

const BoxWithImage = Box as any

export const POSImageWithFallback = ({
    src,
    fallback,
    type = 'image/webp',
    ...props
}: ImgWithFallbackProps) => (
    <picture>
        <source srcSet={src} type={type} />
        <BoxWithImage component="img" src={fallback} {...props} />
    </picture>
)
