import { Modal } from '../../../../components/modal'
import { Box, Button, Typography } from '@material-ui/core'
import { formatCurrency } from 'src/utils'
import { Close } from '@material-ui/icons'
import ModalSection from './components/ModalSection'
import SectionContent from './components/SectionContent'
import parse from 'html-react-parser'
import { ModalProps, PLAN_NAME, PlanKey, PlanProps } from './utils'

const SectionTitle = (
    props: PlanProps & { section: 'cycle' | 'recurrence' }
) => {
    const totalPrice = formatCurrency.format(props.cycleTotalPrice)

    const templateByPlanType: Record<PlanKey, string> = {
        3: `Comprometeremos <b style="color: #002266">${totalPrice}</b> a cada ${props.cycleInMonths} meses do seu limite durante <b style="color: #002266">${props.numberOfCycles} trimestres</b>`,
        6: `Comprometeremos <b style="color: #002266">${totalPrice}</b> a cada ${
            props.cycleInMonths
        } meses do seu limite durante <b style="color: #002266">${[
            props.numberOfCycles,
        ]} semestres</b>`,
        12: `Comprometeremos <b style="color: #002266">${totalPrice}</b> por ano do seu limite durante <b style="color: #002266">${props.numberOfCycles} anos </b>`,
    }

    const template =
        props.section === 'cycle'
            ? `Você pagará <b style="color: #002266">${formatCurrency.format(
                  props.installmentPrice
              )}</b> por mês durante <b style="color: #002266">${
                  props.totalInstallments
              } meses</b>`
            : templateByPlanType[props.cycleInMonths]

    return (
        <Typography style={{ fontSize: '1rem', maxWidth: '230px' }}>
            {parse(template)}
        </Typography>
    )
}

const RecurrenceInfoModal = (props: ModalProps) => {
    return (
        <Modal open={props.open} onClose={props.onClose}>
            <>
                <Box display="flex" justifyContent="space-between">
                    <Typography style={{ fontWeight: 800, fontSize: '1rem' }}>
                        {`Plano de parcelamento ${
                            PLAN_NAME[props.cycleInMonths]
                        }`}
                    </Typography>
                    <Close
                        onClick={props.onClose}
                        color="action"
                        style={{ cursor: 'pointer' }}
                    />
                </Box>
                <Box
                    display="grid"
                    gridAutoFlow={{ xs: 'row', md: 'column' }}
                    justifyContent="center"
                    gridGap="1.5rem"
                    margin="1.5rem 0"
                >
                    <ModalSection
                        sectionTitle={
                            <SectionTitle {...props} section="cycle" />
                        }
                        sectionContent={
                            <SectionContent
                                showInstallmentsCycleHighlight
                                testID="cycle-payment-info"
                                displayInstallmentType="price"
                                {...props}
                            />
                        }
                        index={1}
                    />
                    <ModalSection
                        sectionTitle={
                            <SectionTitle {...props} section="recurrence" />
                        }
                        sectionContent={
                            <SectionContent
                                {...props}
                                showAllItems
                                testID="recurrence-payment-info"
                                displayInstallmentType="identifier"
                                tooltipText={formatCurrency.format(
                                    props.cycleTotalPrice
                                )}
                            />
                        }
                        index={2}
                    />
                </Box>
                <Box display="flex" justifyContent="center">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{
                            width: '20rem',
                        }}
                        onClick={props.onClose}
                    >
                        Fechar
                    </Button>
                </Box>
            </>
        </Modal>
    )
}

export default RecurrenceInfoModal
