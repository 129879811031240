const hasNewRelicOnEnvironment = () => window.newrelic !== undefined

export const setNewrelicUser = ({ email = '' }) => {
    if (hasNewRelicOnEnvironment()) {
        window.newrelic.setCustomAttribute('userEmail', email)
    }
}

export const logError = (error: any, extras = {}) => {
    if (hasNewRelicOnEnvironment()) {
        window.newrelic.noticeError(error, extras)
    } else {
        console.error(error, extras)
    }
}
