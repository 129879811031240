import { FieldConfig, GenericFieldHTMLAttributes, Field } from 'formik'
import { TextFieldProps, InputLabel } from '@material-ui/core'

import { INPUT_CVV_MASK } from 'src/utils/masks'

import { POSTextMask } from 'src/components'

export type POSFieldCvvProps = GenericFieldHTMLAttributes &
    FieldConfig &
    TextFieldProps

export const POSFieldCvv = ({ label, ...props }: POSFieldCvvProps) => (
    <>
        <InputLabel>{label}</InputLabel>
        <Field
            component={POSTextMask}
            mask={INPUT_CVV_MASK}
            variant="filled"
            fullWidth
            inputProps={{
                inputMode: 'numeric',
            }}
            {...props}
        />
    </>
)
