
export const PLAN_TYPE: { [cycleInMonths: number]: Record<string, string> } = {
    3: { key: 'trimestral', label: 'trimestral', description: 'Trimestre'},
    6: { key: 'semestral', label: 'semestral', description: 'Semestre'},
    12: { key: 'annual', label: 'anual', description: 'Ano'},
}

export const PLAN_NAME: { [cycleInMonths: number]: string }  = {
    3: 'trimestral',
    6: 'semestral',
    12: 'anual'
}

export type ModalProps = {
    open: boolean
    onClose: () => void
    installmentPrice: number
    cycleInMonths: number
    numberOfCycles: number
    totalInstallments: number
    cycleTotalPrice: number
}

export type PlanProps = Omit<ModalProps, 'open' | 'onClose'>;

export type PlanKey = keyof typeof PLAN_TYPE;