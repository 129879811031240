import { PaymentMethodType } from 'src/types'

export enum PaymentMethodsId {
    'CREDIT_CARD' = 1,
    'BANK_SLIP',
}

export const getPaymentMethodsId = (
    paymentMethod?: PaymentMethodType
): number | null => {
    switch (paymentMethod) {
        case PaymentMethodType.CREDIT_CARD:
            return PaymentMethodsId.CREDIT_CARD
        case PaymentMethodType.BANK_SLIP:
            return PaymentMethodsId.BANK_SLIP
        default:
            return null
    }
}
