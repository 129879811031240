import { TypographyProps, Typography } from '@material-ui/core'

type MainPriceProps = {
    children: React.ReactNode
} & TypographyProps

export const MainPrice = ({ children, ...props }: MainPriceProps) => {
    return (
        <Typography {...props} variant="h4" style={{ fontWeight: 700 }}>
            {children}
        </Typography>
    )
}
