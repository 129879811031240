import { FC, useContext } from 'react'
import {
    Box,
    Divider,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { formatCurrency } from 'src/utils'
import { IProductData, MainContext } from 'src/providers/Main'
import { ICoupon } from 'src/types'
import { POSPriceWithInstallments } from 'src/components'

type TSummary = {
    productSelected?: IProductData
    couponData?: ICoupon
    isPromoEnabled: boolean
    hasBankSlip?: boolean
}

type TSummaryItem = {
    name: string
    value: string
    type: 'value' | 'discount'
    mb?: number
    mt?: number
    isMobile: boolean
}

const SummaryItem = ({
    name,
    value,
    type,
    isMobile,
    ...props
}: TSummaryItem) => {
    const theme = useTheme()
    const fontSize = isMobile ? '0.9rem' : '1rem'
    return (
        <Box
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            {...props}
        >
            <Typography style={{ fontSize }}>{name}</Typography>
            <Typography
                style={{
                    color:
                        type === 'discount'
                            ? theme.palette.info.main
                            : 'rgba(35, 39, 42, 0.7)',
                    fontWeight: 700,
                    fontSize,
                }}
            >
                {`${type === 'discount' ? ' - ' : ''}${value}`}
            </Typography>
        </Box>
    )
}

const getDiscountValue = (price: number, discount?: number) => {
    if (!discount) return 0

    return price * (discount / 100)
}

const DiscountLabel = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    borderRadius: '4px',
    fontWeight: 700,
    width: '100px',
    padding: '2px 0',
}))

const Summary: FC<TSummary> = ({
    productSelected,
    couponData,
    isPromoEnabled,
    hasBankSlip,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const {
        setInstallmentValueWithCoupon,
        setTotalValueWithCoupon,
        isRecurrenceEnabled,
    } = useContext(MainContext)

    const installments =
        !hasBankSlip &&
        isPromoEnabled &&
        productSelected?.installments! > 1 &&
        !productSelected?.isFirstChargeReductionCampaign
            ? productSelected?.installments! - 1
            : productSelected?.totalInstallments!

    if (!productSelected) return <></>

    const totalDiscountPercentage =
        (couponData?.percentage || 0) +
        (productSelected?.discountsPercentageTotal || 0)

    const initialFullPrice = isRecurrenceEnabled
        ? productSelected.price * productSelected.numberOfCyles
        : productSelected.price *
          productSelected.numberOfCyles *
          productSelected.cycleInMonths

    const couponDiscountValue = getDiscountValue(
        initialFullPrice,
        couponData?.percentage
    )

    const productDiscountValue = isPromoEnabled
        ? productSelected.discountTotalValue
        : getDiscountValue(
              initialFullPrice,
              productSelected.discountsPercentageTotal
          )

    const calculatePriceWithCoupon = () => {
        const priceWithCoupon =
            productSelected.totalValueToPay - couponDiscountValue

        setTotalValueWithCoupon(priceWithCoupon)

        return priceWithCoupon
    }

    const calculateInstallmentWithCoupon = () => {
        const priceWithCoupon = calculatePriceWithCoupon()
        const installmentWithCoupon = priceWithCoupon / installments

        setInstallmentValueWithCoupon(installmentWithCoupon)
        return installmentWithCoupon
    }

    return (
        <Box>
            {!!totalDiscountPercentage && (
                <Box
                    bgcolor={theme.palette.background.default}
                    color={theme.palette.text.secondary}
                    mt={4}
                    p={2}
                >
                    <SummaryItem
                        name="Valor do curso"
                        value={formatCurrency.format(initialFullPrice)}
                        mb={2}
                        type="value"
                        isMobile={isMobile}
                    />
                    <Divider />

                    {!!productDiscountValue && (
                        <SummaryItem
                            name="Desconto Comercial"
                            value={formatCurrency.format(productDiscountValue)}
                            mt={2}
                            type="discount"
                            isMobile={isMobile}
                        />
                    )}

                    {!!couponDiscountValue && (
                        <SummaryItem
                            name="Cupom"
                            value={formatCurrency.format(couponDiscountValue)}
                            mt={2}
                            type="discount"
                            isMobile={isMobile}
                        />
                    )}
                </Box>
            )}
            <Box mt={2}>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {!!totalDiscountPercentage && (
                        <DiscountLabel>{`${totalDiscountPercentage}% desc.`}</DiscountLabel>
                    )}

                    {!hasBankSlip &&
                        isPromoEnabled &&
                        !isRecurrenceEnabled &&
                        productSelected.installments > 1 && (
                            <Box
                                display="flex"
                                flexDirection="row"
                                width={'100%'}
                                justifyContent={'end'}
                            >
                                <Typography
                                    style={{
                                        color: '#072869',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                    }}
                                >
                                    1ª parcela de
                                </Typography>
                                &nbsp;
                                <Typography
                                    style={{
                                        color: '#2CAA6E',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                    }}
                                >
                                    <POSPriceWithInstallments
                                        price={
                                            productSelected.priceFirstInstallment!
                                        }
                                        installments={1}
                                    />
                                </Typography>
                                &nbsp;
                                <Typography
                                    style={{
                                        color: '#2CAA6E',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                    }}
                                >
                                    +
                                </Typography>
                            </Box>
                        )}
                </Box>

                <Box mt={2}>
                    <Typography
                        color="secondary"
                        variant="h3"
                        style={{
                            fontWeight: 'bold',
                            textAlign: 'right',
                            fontSize: '2.5rem',
                        }}
                    >
                        <Typography
                            color="secondary"
                            variant="caption"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '1rem',
                            }}
                        >
                            {`${
                                isRecurrenceEnabled
                                    ? productSelected.totalInstallments
                                    : installments
                            }x de `}
                        </Typography>

                        {formatCurrency.format(
                            couponData?.percentage
                                ? calculateInstallmentWithCoupon()
                                : productSelected.installmentPrice
                        )}
                    </Typography>
                    <Typography
                        style={{
                            fontWeight: 'bold',
                            color: theme.palette.info.main,
                            textAlign: 'right',
                            fontSize: isMobile ? '0.9rem' : '1rem',
                        }}
                    >
                        {`Total: ${formatCurrency.format(
                            couponData?.percentage
                                ? calculatePriceWithCoupon()
                                : productSelected.totalValueToPay
                        )}`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default Summary
