import { useState, useCallback, useEffect } from 'react'

import { Grid, Box, useTheme } from '@material-ui/core'

import { POSHeaderStepper } from 'src/components'

import {
    useCreateLead,
    useMainContext,
    useTrackerContext,
    useScrollToElement,
    useQuery,
} from 'src/hooks'

import { onlyNumbers } from 'src/utils'

import { setNewrelicUser } from 'src/config/log-error'

import {
    Identification,
    SubmitIdentification,
} from 'src/pages/basic-data/Identification'
import {
    AdditionalInfo,
    SubmitAdditionalInfo,
} from 'src/pages/basic-data/AdditionalInfo'
import { useHubspot } from 'src/hooks/use-hubspot'

type BasicDataProps = {
    activeStep?: number
}

const BasicData = ({ activeStep: activeStepProp = 0 }: BasicDataProps) => {
    const [activeStep, setActiveStep] = useState(activeStepProp)

    const theme = useTheme()

    const { mutateAsync: createLead } = useCreateLead()
    const {
        data,
        setBuyer,
        buyer,
        utmSource,
        utmMedium,
        utmCampaign,
        goToPayment,
    } = useMainContext()
    const { goScrollTo } = useScrollToElement(theme.breakpoints.down('sm'))

    const { track, identify } = useTrackerContext()

    const query = useQuery()

    const { sendPersonalForm } = useHubspot(query)

    useEffect(() => {
        if (!!data?.id) {
            track({
                eventName: 'Checkout page viewed',
                additionalAttrs: {
                    'Course name': data?.name,
                    'Course duration': `${data?.duration} meses`,
                },
            })
        }
    }, [track, data?.name, data?.id, data?.duration])

    const handleSubmitIndentification: SubmitIdentification = (values) => {
        setBuyer((prev) => ({ ...prev, ...values }))

        sendPersonalForm({
            phone: `${values?.countryCode} ${onlyNumbers(values?.phone)}`,
            email: values.email!,
            name: values.name!,
            course: data.name,
        })

        track({
            eventName: 'Personal info filled',
            additionalAttrs: {
                'Course name': data?.name,
                'Course duration': `${data?.duration} meses`,
            },
        })
        identify({
            'E-mail': values?.email!,
        })

        setNewrelicUser({ email: values?.email })

        return createLead({
            name: values?.name!,
            email: values?.email!,
            phone: `${values?.countryCode} ${onlyNumbers(values?.phone)}`,
            courseId: data?.id!,
            utmSource,
            utmMedium,
            utmCampaign,
        }).finally(() => {
            setActiveStep(1)
            goScrollTo('address')
        })
    }

    const handleSubmitAdditionalInfo: SubmitAdditionalInfo = (values) => {
        setBuyer((prev) => ({ ...prev, address: values }))

        track({
            eventName: 'Additional info filled',
            additionalAttrs: {
                'Course name': data?.name,
                'Course duration': `${data?.duration} meses`,
            },
        })

        identify({
            Estado: values?.state!,
            Cidade: values?.city!,
        })

        goToPayment()
    }

    const handleBack = useCallback(() => {
        setActiveStep(0)
    }, [])

    return (
        <Box mb={15}>
            <POSHeaderStepper activeStep={0} courseName={data?.name || ''} />

            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={4}>
                    <Identification
                        initialValues={buyer || {}}
                        isRight={activeStep === 1}
                        onSubmit={handleSubmitIndentification}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={8} id="Additional-info">
                    <AdditionalInfo
                        initialValues={buyer?.address || {}}
                        disabled={activeStep === 0}
                        onSubmit={handleSubmitAdditionalInfo}
                        onBack={handleBack}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default BasicData
