import { Box, Typography, Link, Button, Divider } from '@material-ui/core'

import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'

import { POSCard } from 'src/components'

type ConfimedOrderProps = {
    matches?: boolean
    duration: number
    startDate?: string
    praticalWorkload?: number
    eadWorkload: number
    name: string
    value: string
    barCode?: string
    bankSlipUrl?: string
    valueFirstInstallmentFormatted: string
}

export const ConfimedOrder = ({
    matches,
    duration,
    startDate,
    praticalWorkload,
    eadWorkload,
    name,
    value,
    barCode,
    bankSlipUrl,
    valueFirstInstallmentFormatted,
}: ConfimedOrderProps) => {
    const { enqueueSnackbar } = useSnackbar()
    const fontSizeCommon = matches ? 'caption' : 'body2'

    const handleCopyToClipboard = () => {
        copy(barCode)
        enqueueSnackbar('Código copiado', { variant: 'info' })
    }

    const textAlign = matches ? 'center' : 'left'

    return (
        <POSCard
            badgeTitle="Pedido confirmado"
            wrapperProps={{ maxWidth: 968 }}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            px={{ xs: 2, md: 4 }}
            pb={{ xs: 0, md: 4 }}
        >
            <Box py={2} flex={1}>
                <Typography
                    color="secondary"
                    variant="body2"
                    style={{ fontWeight: 700 }}
                >
                    Pós Graduação Online
                </Typography>
                <Typography
                    color="primary"
                    variant={matches ? 'h6' : 'h5'}
                    paragraph
                    style={{ fontWeight: 700 }}
                >
                    {name}
                </Typography>
                <Box display="flex" alignItems="center">
                    <Typography
                        color="textSecondary"
                        variant={fontSizeCommon}
                        style={{ fontWeight: 700 }}
                    >
                        Duração
                    </Typography>
                    <Typography variant={fontSizeCommon} color="textSecondary">
                        {`: ${duration} meses`}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" my={1}>
                    <Typography
                        color="textSecondary"
                        variant={fontSizeCommon}
                        style={{ fontWeight: 700 }}
                    >
                        Início
                    </Typography>
                    <Typography variant={fontSizeCommon} color="textSecondary">
                        : {startDate}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography
                        color="textSecondary"
                        variant={fontSizeCommon}
                        style={{ fontWeight: 700 }}
                    >
                        Carga horária
                    </Typography>
                    <Typography variant={fontSizeCommon} color="textSecondary">
                        : {eadWorkload} EAD
                        {praticalWorkload &&
                            ` + ${praticalWorkload}h Aulas Práticas`}
                    </Typography>
                </Box>
            </Box>

            <Box mx={{ xs: 0, md: 8 }}>
                <Divider orientation={matches ? 'horizontal' : 'vertical'} />
            </Box>

            {!!bankSlipUrl ? (
                <Box py={2} flex={1}>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ maxWidth: 307 }}
                    >
                        Para concluir, basta pagar{' '}
                        <Typography
                            component="span"
                            style={{ fontWeight: 700 }}
                        >
                            {value}
                        </Typography>{' '}
                        com Boleto bancário.
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection={{ xs: 'column', md: 'row' }}
                        alignItems={{ xs: 'normal', md: 'center' }}
                        mt={3}
                        mb={2}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth={matches}
                            size="large"
                            href={bankSlipUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Visualizar boleto
                        </Button>
                        <Box mr={{ xs: 0, md: 4 }} mt={{ xs: 2, md: 0 }} />
                        {barCode !== 'undefined' && (
                            <Button
                                component={Link}
                                underline="always"
                                color="primary"
                                style={{
                                    fontWeight: 700,
                                }}
                                onClick={handleCopyToClipboard}
                            >
                                Copiar código do boleto
                            </Button>
                        )}
                    </Box>
                    <Typography
                        color="textSecondary"
                        style={{ fontSize: '0.625rem' }}
                        align={textAlign}
                    >
                        Uma cópia será enviada automaticamente via e-mail.
                    </Typography>
                </Box>
            ) : (
                <Box flex={1} display="flex" alignItems="center" py={2}>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ maxWidth: 330 }}
                    >
                        Estamos aguardando a operadora do seu cartão confirmar o
                        pagamento. Você será informado por e-mail.
                    </Typography>
                </Box>
            )}
        </POSCard>
    )
}
