import React from 'react'
import {
    Box,
    Button,
    Grid,
    Typography,
    Link,
    useMediaQuery,
} from '@material-ui/core'
import { ReactComponent as LogoSymbol } from 'src/assets/images/logo-symbol.svg'

import { POSCard } from 'src/components/card'
import { POSFieldCheckbox, POSFieldFormik } from 'src/components'
import { testID } from 'src/config/test'
import { Form, Formik } from 'formik'
import { cpf } from 'cpf-cnpj-validator'
import * as Yup from 'yup'
import { useRegisterSignature } from 'src/hooks/use-register-signature'
import { useHistory } from 'react-router-dom'
import { paths } from 'src/config/routes-paths'
import { theme } from 'src/theme'
import { onlyNumbers } from 'src/utils'
import { useMainContext } from 'src/hooks'
import { env } from 'src/config/enviroments'

interface SignatureFormValues {
    fullName: string
    email: string
    cpf: string
    acceptTerms: boolean
}

const validationSchema = Yup.object().shape({
    fullName: Yup.string()
        .required('Nome é obrigatório')
        .matches(
            /^[a-z\u00C0-\u00FF\s]*$/gi,
            'Seu nome não pode conter números ou símbolos'
        )
        .matches(/[\w\W]{1,10}(\s).{1,}/gi, 'Insira o seu nome completo'),
    email: Yup.string()
        .required('E-mail é obrigatório')
        .email('Insira um e-mail válido')
        .matches(
            /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            'Informe um E-mail válido.'
        ),
    cpf: Yup.string()
        .required('CPF é obrigatório')
        .min(11, 'Insira um número com 11 dígitos')
        .test('cpf', 'Informe um CPF válido.', (value) => cpf.isValid(value)),
    acceptTerms: Yup.boolean().required(),
})

const initialValues: SignatureFormValues = {
    fullName: '',
    email: '',
    cpf: '',
    acceptTerms: false,
}

const ContractRegularization = () => {
    const { mutate } = useRegisterSignature()
    const { push } = useHistory()
    const { setSignature } = useMainContext()

    const onSubmit = (signature: SignatureFormValues) => {
        mutate(
            {
                fullName: signature.fullName,
                cpf: onlyNumbers(signature.cpf),
                email: signature.email,
            },
            {
                onSuccess: () => {
                    setSignature(signature)
                    push(paths['contractRegularizationConfirmation'])
                },
            }
        )
    }

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Box>
            <Box display="flex" alignItems="center">
                <LogoSymbol width={isMobile ? 16 : 24} />
                <Typography
                    color="secondary"
                    style={{
                        fontWeight: 700,
                        marginLeft: 13.5,
                        fontSize: isMobile ? 12 : 16,
                    }}
                >
                    Pós-graduação Online
                </Typography>
            </Box>
            <Typography
                color="primary"
                style={{
                    fontWeight: 700,
                    fontSize: isMobile ? 32 : 42,
                    marginTop: isMobile ? 0 : 16,
                }}
            >
                Regularize sua matrícula
            </Typography>
            <Grid
                container
                spacing={10}
                style={{ marginTop: isMobile ? -8 : 32, marginBottom: 72 }}
            >
                <Grid item xs={12} sm={4}>
                    <Typography
                        variant={isMobile ? 'body1' : 'h5'}
                        style={{ fontWeight: 500 }}
                    >
                        Para concluir sua matrícula, é importante que assine o
                        contrato com a sua Instituição de Ensino.
                    </Typography>

                    <Typography
                        variant={isMobile ? 'body2' : 'body1'}
                        style={{ marginTop: 24 }}
                    >
                        Para prosseguirmos, é necessário ler e estar de acordo
                        com os termos do contrato:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    style={{ marginTop: isMobile ? -32 : 0 }}
                >
                    <POSCard p={2}>
                        <Box>
                            <Typography variant={isMobile ? 'body2' : 'body1'}>
                                É necessário ler, preencher os dados e estar de
                                acordo:
                            </Typography>

                            <Formik
                                onSubmit={(values: SignatureFormValues) => {
                                    onSubmit(values)
                                }}
                                validationSchema={validationSchema}
                                initialValues={initialValues}
                            >
                                {({ values }) => {
                                    return (
                                        <Form>
                                            <Grid
                                                container
                                                spacing={4}
                                                style={{ marginTop: 16 }}
                                            >
                                                <Grid item sm={6} xs={12}>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                    >
                                                        <POSFieldFormik
                                                            required
                                                            name="cpf"
                                                            label="CPF:"
                                                            placeholder="Insira seu CPF"
                                                            fieldType="cpf"
                                                            inputProps={{
                                                                ...testID(
                                                                    'cpf'
                                                                ),
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>

                                                <Grid item sm={6} xs={12}>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                    >
                                                        <POSFieldFormik
                                                            required
                                                            name="email"
                                                            label="E-mail:"
                                                            placeholder="Insira seu e-mail"
                                                            inputProps={{
                                                                ...testID(
                                                                    'email'
                                                                ),
                                                                maxLength: 100,
                                                                inputMode:
                                                                    'email',
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={12} xs={12}>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                    >
                                                        <POSFieldFormik
                                                            required
                                                            name="fullName"
                                                            label="Nome completo:"
                                                            placeholder="Insira seu nome completo"
                                                            inputProps={{
                                                                ...testID(
                                                                    'fullName'
                                                                ),
                                                                maxLength: 100,
                                                                inputMode:
                                                                    'text',
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <POSFieldCheckbox
                                                        name="acceptTerms"
                                                        Label={{
                                                            label: (
                                                                <Typography
                                                                    color="textSecondary"
                                                                    style={{
                                                                        lineHeight: 1,
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    Declaro que
                                                                    li e estou
                                                                    de acordo
                                                                    com o{' '}
                                                                    <Link
                                                                        underline="always"
                                                                        color="primary"
                                                                        href={
                                                                            env.POS_CONTRACT_REGULARIZATION
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        Contrato
                                                                        de
                                                                        Prestação
                                                                        de
                                                                        Serviços
                                                                        Educacionais
                                                                        com a
                                                                        Uniamérica
                                                                    </Link>
                                                                </Typography>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                {!isMobile && (
                                                    <Grid item sm={6} />
                                                )}
                                                <Grid item sm={6} xs={12}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        fullWidth
                                                        disabled={
                                                            !values.acceptTerms
                                                        }
                                                    >
                                                        Confirmar
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Box>
                    </POSCard>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ContractRegularization
