import { Field, getIn } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'

import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

export type POSPOSFieldCheckboxProps = any

export const CustomCheckbox = (props: POSPOSFieldCheckboxProps) => {
    const fieldError = getIn(props?.form?.errors, props?.field.name)

    return (
        <FormControl error={!!fieldError}>
            <InputLabel>{props?.label}</InputLabel>
            <CheckboxWithLabel {...props} />
            {fieldError && (
                <FormHelperText error variant="filled">
                    {fieldError}
                </FormHelperText>
            )}
        </FormControl>
    )
}

export const POSFieldCheckbox = (props: POSPOSFieldCheckboxProps) => (
    <Field
        component={CustomCheckbox}
        type="checkbox"
        variant="filled"
        style={{ color: '#00297B' }}
        {...props}
    />
)
