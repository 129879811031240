import { Box, Typography } from '@material-ui/core'
import { formatStartDate } from 'src/utils'

type DetailsProps = {
    matches?: boolean
    duration: number
    startDate?: string
    praticalWorkload?: number
    eadWorkload: number
    immediateStart?: boolean
    isPromoEnabled: boolean
}

export const Details = ({
    matches,
    duration,
    startDate,
    praticalWorkload,
    eadWorkload,
    immediateStart,
    isPromoEnabled,
}: DetailsProps) => (
    <Box flex={1} display="flex" flexDirection="column" justifyContent="center">
        <Box display="flex" alignItems="center">
            <Typography
                variant={matches ? 'caption' : 'body2'}
                style={{
                    fontWeight: 700,
                    color:
                        !isPromoEnabled && immediateStart
                            ? '#E0EBFF'
                            : 'textSecondary',
                }}
            >
                Duração
            </Typography>
            <Typography
                variant={matches ? 'caption' : 'body2'}
                style={{
                    color:
                        !isPromoEnabled && immediateStart
                            ? '#E0EBFF'
                            : 'textSecondary',
                }}
            >
                {`: ${duration} meses`}
            </Typography>
        </Box>
        <Box display="flex" alignItems="center" my={1}>
            <Typography
                variant={matches ? 'caption' : 'body2'}
                style={{
                    fontWeight: 700,
                    color:
                        !isPromoEnabled && immediateStart
                            ? '#E0EBFF'
                            : 'textSecondary',
                }}
            >
                Data de início:&nbsp;
            </Typography>
            {immediateStart ? (
                <Typography
                    variant={matches ? 'caption' : 'body2'}
                    style={{
                        color: !isPromoEnabled ? '#FFF27C' : '#002266',
                        fontWeight: isPromoEnabled ? 700 : 400,
                    }}
                >
                    INÍCIO IMEDIATO
                </Typography>
            ) : (
                <Typography
                    variant={matches ? 'caption' : 'body2'}
                    color="textSecondary"
                >
                    {startDate && formatStartDate(startDate)}
                </Typography>
            )}
        </Box>
        <Box display="flex" alignItems="center">
            <Typography
                variant={matches ? 'caption' : 'body2'}
                style={{
                    fontWeight: 700,
                    color:
                        !isPromoEnabled && immediateStart
                            ? '#E0EBFF'
                            : 'textSecondary',
                }}
            >
                Carga horária
            </Typography>
            <Typography
                variant={matches ? 'caption' : 'body2'}
                style={{
                    color:
                        !isPromoEnabled && immediateStart
                            ? '#E0EBFF'
                            : 'textSecondary',
                }}
            >
                : {eadWorkload}h EAD
                {praticalWorkload && ` + ${praticalWorkload}h Aulas Práticas`}
            </Typography>
        </Box>
    </Box>
)
