import { Box, Link, Typography } from '@material-ui/core'
import { POSCard, POSCoupon } from 'src/components'
import { IProductData } from 'src/providers/Main/Main'
import { IComboVariant, PaymentMethodType } from 'src/types'
import PaymentSelection from './payment-selection/PaymentSelection'
import Summary from './summary/Summary'
import RecurrenceSelection from './payment-selection/RecurrenceSelection'
import { useHistory } from 'react-router-dom'
import comboHelper from '../../utils/combo-helper'

type PlansProps = {
    handleChangePlan: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleApplyCoupon: (coupon: string) => void
    defaultCouponValue?: string
    products?: IProductData[]
    isLoadingCoupon?: boolean
    status?: 'success' | 'error'
    helperTextCoupon?: string
    installments?: number
    priceFirstInstallment?: number
    productSelected?: IProductData
    setProductSelectValue: any
    paymentMethodSelectValue: PaymentMethodType
    hasBankSlip?: boolean
    isPromoEnabled: boolean
    isRecurrenceEnabled: boolean
    isCouponEnabled: boolean
    comparePricesFlag: boolean
    openModal: () => void
    couponData: any
    comboVariant?: IComboVariant[]
}

export const Plans = ({
    handleChangePlan,
    handleApplyCoupon,
    defaultCouponValue,
    products = [],
    isLoadingCoupon,
    status,
    helperTextCoupon,
    productSelected,
    setProductSelectValue,
    paymentMethodSelectValue,
    hasBankSlip = false,
    isPromoEnabled,
    isRecurrenceEnabled,
    isCouponEnabled,
    comparePricesFlag,
    openModal,
    couponData,
    comboVariant,
}: PlansProps) => {
    const history = useHistory()

    const isCurrentCourseInCombo = comboHelper(
        comboVariant
    ).uniqueCourses?.some((course) =>
        history.location.pathname.includes(course)
    )

    const shouldShowCoupon =
        !isCurrentCourseInCombo && isCouponEnabled && Boolean(productSelected)

    return (
        <POSCard px={2} pt={2} pb={4}>
            {isRecurrenceEnabled ? (
                <RecurrenceSelection
                    productSelected={productSelected}
                    products={products}
                    setProductSelectValue={setProductSelectValue}
                />
            ) : (
                <PaymentSelection
                    products={products}
                    setProductSelectValue={setProductSelectValue}
                    productSelected={productSelected}
                    paymentMethodSelectValue={paymentMethodSelectValue}
                    handleChangePlan={handleChangePlan}
                    hasBankSlip={hasBankSlip}
                    isPromoEnabled={isPromoEnabled}
                    isRecurrenceEnabled={isRecurrenceEnabled}
                />
            )}

            <Box mt={2} />

            {!isRecurrenceEnabled && comparePricesFlag && (
                <Typography
                    onClick={() => openModal()}
                    component={Link}
                    underline={'always'}
                    color="primary"
                    style={{
                        cursor: 'pointer',
                        fontSize: '12px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        fontWeight: 500,
                    }}
                >
                    Comparar condições de pagamento
                </Typography>
            )}

            {isPromoEnabled &&
                !isRecurrenceEnabled &&
                productSelected &&
                productSelected.paymentMethod ===
                    PaymentMethodType.CREDIT_CARD &&
                productSelected.installments > 1 && (
                    <Box mt={1} display="flex" justifyContent="flex-end">
                        <Typography
                            color="textPrimary"
                            style={{ fontSize: '10px', fontWeight: 500 }}
                        >
                            *Não válida para pagamentos à vista
                        </Typography>
                    </Box>
                )}

            {!comparePricesFlag && (
                <Typography
                    color="primary"
                    variant="caption"
                    style={{ fontWeight: 700 }}
                >
                    *Parcelamento via Cartão de Crédito que não compromete seu
                    limite.
                </Typography>
            )}
            <Box mt={3} />

            {shouldShowCoupon && (
                <POSCoupon
                    helperText={helperTextCoupon}
                    status={status}
                    onSend={handleApplyCoupon}
                    defaultValue={defaultCouponValue}
                    isLoading={isLoadingCoupon}
                />
            )}

            <Summary
                couponData={couponData}
                productSelected={productSelected}
                isPromoEnabled={isPromoEnabled}
                hasBankSlip={hasBankSlip}
            />
        </POSCard>
    )
}
