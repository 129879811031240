import React from 'react'

import sanarAnalytics from '../config/trackers/sanarAnalytics';

import { MaterialUIProvider } from './MaterialUI'
import { ReactQueryProvider } from './ReactQuery'
import { ReactRouterProvider } from './ReactRouter'
import { TrackerProvider } from './Tracker'
import { MainProvider } from './Main'
import { NotistackProvider } from './Notistack'
import { ErrorBoundary } from './ErrorBoundary'
import { UnleashProvider } from './Unleash'

export const Providers = ({ children }: React.PropsWithChildren<{}>) => (
    <UnleashProvider>
        <MaterialUIProvider>
            <ErrorBoundary>
                <ReactQueryProvider>
                    <ReactRouterProvider>
                        <NotistackProvider>
                            <MainProvider>
                                <TrackerProvider trackers={[sanarAnalytics]}>
                                    {children}
                                </TrackerProvider>
                            </MainProvider>
                        </NotistackProvider>
                    </ReactRouterProvider>
                </ReactQueryProvider>
            </ErrorBoundary>
        </MaterialUIProvider>
    </UnleashProvider>
)
