import React from 'react'
import { Typography, TypographyProps } from '@material-ui/core'

type SubtitleProps = {
    children: React.ReactNode
} & TypographyProps

export const Subtitle = ({ children, ...props }: SubtitleProps) => {
    return (
        <>
            <Typography
                variant="subtitle1"
                style={{ textDecoration: 'line-through' }}
                {...props}
            >
                {children}
            </Typography>
        </>
    )
}
