import {
    Box,
    Stepper,
    Step,
    StepperProps,
    Hidden,
    Typography,
    LinearProgress,
} from '@material-ui/core'

import { testID } from 'src/config/test'

import { POSStepperConnector } from './Connector'
import { POSStepIcon, IconNumber } from './StepIcon'
import { POSStepLabel } from './StepLabel'

export type POSStepperProps = Omit<StepperProps, 'children'>

const steps = ['Dados Básicos', 'Dados de Pagamento', 'Confirmação']

export const POSStepper = ({ activeStep = 0, ...props }: POSStepperProps) => {
    const valueProgress =
        activeStep > steps.length - 1
            ? 100
            : Math.ceil((activeStep / (steps.length - 1)) * 100)

    return (
        <>
            <Hidden xsDown>
                <Stepper
                    alternativeLabel
                    connector={<POSStepperConnector />}
                    style={{ backgroundColor: 'transparent' }}
                    activeStep={activeStep}
                    {...props}
                >
                    {steps.map((step, index) => (
                        <Step key={`step-${index}`}>
                            <POSStepLabel StepIconComponent={POSStepIcon}>
                                {step}
                            </POSStepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Hidden>

            <Hidden smUp>
                <LinearProgress
                    variant="determinate"
                    value={valueProgress}
                    {...testID('progress')}
                />
                <Box display="flex" alignItems="center" mt={2}>
                    <IconNumber icon={activeStep + 1} active />
                    <Box ml={1} />
                    <Typography
                        color="primary"
                        variant="body2"
                        style={{ fontWeight: 500 }}
                    >
                        {steps[activeStep]}
                    </Typography>
                </Box>
            </Hidden>
        </>
    )
}
