import React from 'react'

import { SnackbarProvider } from 'notistack'

export const NotistackProvider = ({
    children,
}: React.PropsWithChildren<{}>) => {
    return (
        <SnackbarProvider
            preventDuplicate
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            {children}
        </SnackbarProvider>
    )
}
