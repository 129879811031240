import { createTheme } from '@material-ui/core'

import { bold, medium, regular } from './font'

const themeDefault = createTheme({
    palette: {
        primary: {
            main: '#0050F0',
            light: '#6196FF',
            dark: '#072869',
        },
        secondary: {
            main: '#00297B',
        },
        warning: {
            main: '#ffb74d',
            light: '#FFF27C',
        },
        text: {
            primary: '#414545',
            secondary: '#23272A',
            hint: '#595E5E',
        },
        success: {
            main: '#2CAA6E',
        },
        info: {
            main: '#2CAA6E',
            light: '#FAFAFA'
        },
        action: {
            disabled: '#727878',
        },
        background: {
            default: '#F7F8FA',
        },
    },
    typography: {
        fontFamily: 'Bw Modelica, Roboto',
        subtitle1: {
            color: '#072869',
            fontSize: '10px',
        },
        subtitle2: {
            fontWeight: 700,
            color: '#0050F0',
            fontSize: '18px',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [bold, medium, regular],
            },
        },
        MuiButton: {
            root: {
                textTransform: 'inherit',
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: '0.75rem',
                marginBottom: 8,
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: '#F7F8FA',
            },
            input: {
                padding: '16px',
            },
        },
        MuiRadio: {
            colorSecondary: {
                '&.Mui-checked': {
                    color: '#004FEF',
                },
            },
        },
        MuiSelect: {
            icon: {
                right: '0.5vw',
            },
            select: {
                '&:focus': {
                    backgroundColor: 'inherit',
                },
            },
        },
    },
})

// Created to add new shadow, and not override default
export const theme = {
    ...themeDefault,
    shadows: [...themeDefault.shadows, '0px 4px 4px rgba(0, 0, 0, 0.25)'],
}
