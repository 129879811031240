import { TextField, withStyles } from '@material-ui/core'

export const TextFieldCustom = withStyles((theme) => {
    return {
        root: {
            '& .Mui-focused .MuiInputAdornment-positionEnd svg': {
                color: theme.palette.primary.main,
            },
            '& .MuiFormHelperText-root.Mui-focused': {
                color: theme.palette.primary.main,
            },

            '& .Mui-error .MuiInputAdornment-positionEnd svg': {
                color: theme.palette.error.main,
            },
            '& .Mui-error .MuiFormHelperText-root': {
                color: theme.palette.error.main,
            },
            '& .Mui-error.MuiFormHelperText-root': {
                color: theme.palette.error.main,
            },

            '&.success .MuiInputAdornment-positionEnd svg': {
                color: theme.palette.success.main,
            },
            '&.success .MuiFilledInput-underline:after': {
                transform: 'scaleX(1)',
                borderBottomColor: theme.palette.success.main,
            },
            '& .MuiFilledInput-input': {
                padding: '20px 0 20px 16px',
            },
            '&.success .MuiFormHelperText-root': {
                color: theme.palette.success.main,
            },
            '& .MuiInputBase-input::placeholder': {
                color: theme.palette.text.hint,
                opacity: 1,
            },
        },
    }
})(TextField)
