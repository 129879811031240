import { formatCurrency } from 'src/utils'

type POSPriceWithInstallmentsProps = {
    price: number
    installments?: number
}

export const POSPriceWithInstallments = ({
    price,
    installments = 1,
}: POSPriceWithInstallmentsProps) => (
    <>
        {installments > 1 && `${installments}x de `}
        {formatCurrency.format(price)}
    </>
)
