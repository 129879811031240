import { Field } from 'formik'
import { TextField } from 'formik-material-ui'

import InputLabel from '@material-ui/core/InputLabel'

export type POSFieldTextProps = any

export const POSFieldText = ({ label, ...props }: POSFieldTextProps) => (
    <>
        <InputLabel>{label}</InputLabel>
        <Field component={TextField} variant="filled" fullWidth {...props} />
    </>
)
