import React from 'react'
import { Box, Typography, useMediaQuery } from '@material-ui/core'

import { ReactComponent as LogoSymbol } from 'src/assets/images/logo-symbol.svg'
import { theme } from 'src/theme'
import { POSVerifyRouteAccess } from 'src/components'
import { useMainContext } from 'src/hooks'

const ContractRegularizationConfirmation = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { signature } = useMainContext()

    return (
        <>
            <POSVerifyRouteAccess condition={!signature.cpf} isContractPage />
            <Box maxWidth={600}>
                <Box display="inline-flex" alignItems="center" mb={1}>
                    <LogoSymbol width={isMobile ? 24 : 34} />
                    <Box mr={1} />
                    <Typography
                        color="primary"
                        variant={isMobile ? 'h4' : 'h3'}
                        style={{ fontWeight: 700 }}
                    >
                        Pronto!
                    </Typography>
                </Box>
                <Box mt={2} marginBottom={isMobile ? 7 : 42.375}>
                    <Typography
                        variant={isMobile ? 'body1' : 'h5'}
                        style={{
                            fontWeight: 500,
                        }}
                    >
                        Seu contrato foi devidamente assinado!
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default ContractRegularizationConfirmation
