import { Box, Grid, Link, Button } from '@material-ui/core'

type FooterFormpProps = {
    isSubmitting?: boolean
    disabled?: boolean
    onBack: () => void
}

export const FooterForm = ({
    disabled,
    isSubmitting,
    onBack,
}: FooterFormpProps) => (
    <Grid item xs={12}>
        <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexDirection={{
                xs: 'column',
                sm: 'row',
            }}
            mt={{ xs: 0, md: 4 }}
        >
            <Box order={{ xs: 1, sm: 0 }}>
                <Button
                    onClick={onBack}
                    disabled={isSubmitting || disabled}
                    component={Link}
                    underline="always"
                    color="primary"
                    style={{
                        fontWeight: 700,
                    }}
                >
                    Voltar
                </Button>
            </Box>
            <Box
                width={{
                    xs: '100%',
                    sm: 320,
                }}
                mb={{ xs: 2, sm: 0 }}
                ml={{ xs: 0, sm: 4 }}
            >
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    disabled={isSubmitting || disabled}
                >
                    Prosseguir para pagamento
                </Button>
            </Box>
        </Box>
    </Grid>
)
