import {
    Box,
    Typography,
    Button,
    Link,
    LinearProgress,
} from '@material-ui/core'

import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { POSFieldCheckbox } from 'src/components'

import { env } from 'src/config/enviroments'
import { Maintainer } from 'src/providers/Main'
import { useMainContext } from 'src/hooks'

const initialValues = {
    acceptTerms: false,
}

export type ContractFormValues = Partial<typeof initialValues>

export type SubmitContract = (
    values: ContractFormValues,
    formik: FormikHelpers<ContractFormValues>
) => Promise<void>

const validationSchema = Yup.object().shape({
    acceptTerms: Yup.boolean().oneOf(
        [true],
        'Deve aceitar as condições do contrato'
    ),
})

type ContractProps = {
    onSubmit: SubmitContract
    onBack: () => void
}

export const Contract = ({ onBack, onSubmit }: ContractProps) => {
    const { maintainer, contract } = useMainContext()

    const contractMaintainers = {
        [Maintainer.CETRUS]: (
            <>
                <Link
                    underline="always"
                    color="inherit"
                    href={contract ? contract.link : env.POS_CONTRACT}
                    target="_blank"
                    rel="noreferrer"
                >
                    condições do contrato
                </Link>{' '}
                e da{' '}
                <Link
                    underline="always"
                    color="inherit"
                    href={env.POS_PRIVACY}
                    target="_blank"
                    rel="noreferrer"
                >
                    política de privacidade.
                </Link>
            </>
        ),
    }

    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            initialValues={initialValues as any}
        >
            {({ isSubmitting }) => (
                <Form>
                    {isSubmitting ? (
                        <Box mt={4}>
                            <LinearProgress />
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                mt={1}
                            >
                                <Typography
                                    color="primary"
                                    variant="caption"
                                    align="center"
                                    style={{
                                        fontWeight: 500,
                                    }}
                                >
                                    Finalizando seu pedido
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            mt={4}
                            display="flex"
                            alignItems={{ xs: 'normal', md: 'center' }}
                            justifyContent="space-between"
                            flexDirection={{ xs: 'column', md: 'row' }}
                        >
                            <POSFieldCheckbox
                                name="acceptTerms"
                                Label={{
                                    label: (
                                        <Typography
                                            color="textSecondary"
                                            variant="caption"
                                        >
                                            Li e aceito as{' '}
                                            {!!maintainer &&
                                                contractMaintainers[maintainer]}
                                        </Typography>
                                    ),
                                }}
                            />

                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                                flexDirection={{
                                    xs: 'column',
                                    sm: 'row',
                                }}
                            >
                                <Box order={{ xs: 1, sm: 0 }}>
                                    <Button
                                        onClick={onBack}
                                        disabled={isSubmitting}
                                        component={Link}
                                        underline="always"
                                        color="primary"
                                        style={{
                                            fontWeight: 700,
                                        }}
                                    >
                                        Revisar informações
                                    </Button>
                                </Box>

                                <Box
                                    width={{
                                        xs: '100%',
                                        sm: 196,
                                    }}
                                    mt={{ xs: 3, sm: 0 }}
                                    mb={{ xs: 2, sm: 0 }}
                                    ml={{ xs: 0, sm: 4 }}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        disabled={isSubmitting}
                                    >
                                        Confirmar
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Form>
            )}
        </Formik>
    )
}
