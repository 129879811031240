import { Box, Hidden } from '@material-ui/core'

import { Courses } from './Courses'
import { About } from './About'
import { Help } from './Help'

export const RightFooter = () => {
    return (
        <Box flex={1}>
            <Hidden xsDown>
                <Box height={112} />
            </Hidden>
            <Box
                bgcolor="secondary.main"
                p={{ xs: 4, lg: 5 }}
                color="common.white"
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
            >
                <Courses />

                <About />
            </Box>

            <Help />
        </Box>
    )
}
