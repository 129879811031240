import { Card, Box, BoxProps, Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import { omit } from 'lodash'

import { testID } from 'src/config/test'

type CardProps = {
    children: React.ReactNode
    badgeTitle?: string
    wrapperProps?: BoxProps
    badgeProps?: BoxProps & { isLoading?: boolean }
} & BoxProps

export const POSCard = ({
    children,
    badgeTitle,
    wrapperProps,
    badgeProps,
    ...props
}: CardProps) => {
    return (
        <Box position="relative" {...wrapperProps} {...testID('wrapper')}>
            {!!badgeTitle && (
                <Box
                    bgcolor="primary.main"
                    color="common.white"
                    py="6px"
                    px={2}
                    borderRadius={18}
                    position="absolute"
                    left={32}
                    top={-16}
                    {...omit(badgeProps, ['isLoading'])}
                    {...testID('badge')}
                >
                    <Typography variant="caption" style={{ fontWeight: 700 }}>
                        {badgeProps?.isLoading ? (
                            <Skeleton width={150} />
                        ) : (
                            badgeTitle
                        )}
                    </Typography>
                </Box>
            )}

            <Card elevation={25}>
                <Box p={4} {...props}>
                    {children}
                </Box>
            </Card>
        </Box>
    )
}
