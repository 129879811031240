import { Box } from '@material-ui/core'
import { LeftFooter } from './LeftFooter'
import { RightFooter } from './RightFooter'

export const POSFooter = () => {
    return (
        <Box
            display="flex"
            component="footer"
            flexDirection={{ xs: 'column', sm: 'row' }}
        >
            <LeftFooter />

            <RightFooter />
        </Box>
    )
}
