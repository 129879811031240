import { Tracker } from './types'
import { Metrics } from '@sanardigital/analytics-tracker'

const sanarAnalytics: Tracker = {
    name: 'SanarAnalytics',
    init: () => {
        Metrics.init()
    },
    track: (params) => {
        const { eventName, additionalAttrs = {} } = params
        Metrics.sendEvent({
            eventName,
            properties: additionalAttrs,
            application: 'posmed',
        })
    },
    identify: (user) => {
        Metrics.identify(user)
    },
}
export default sanarAnalytics
