import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core'

import {
    ErrorBoundary,
    ErrorBoundaryPropsWithComponent,
} from 'react-error-boundary'

import { ReactComponent as LogoSymbol } from 'src/assets/images/logo-symbol.svg'

import { POSLayout } from 'src/components'
import { Maintainer } from 'src/providers/Main'

type POSErrorBoundaryProps = Partial<ErrorBoundaryPropsWithComponent> & {
    children: React.ReactNode
}

export const ErrorFallback = () => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <POSLayout hasBackgroundLogo maintainer={Maintainer.CETRUS}>
            <Box mb={{ xs: 8, md: 64 }} maxWidth={447}>
                <Box display="inline-flex" alignItems="center" mb={3}>
                    <LogoSymbol />
                    <Box mr={1} />
                    <Typography
                        color="primary"
                        variant={matches ? 'h4' : 'h3'}
                        style={{ fontWeight: 700 }}
                    >
                        Ops!
                    </Typography>
                </Box>

                <Typography variant={matches ? 'body1' : 'h5'}>
                    Um erro inesperado ocorreu, atualize a pagina e tente
                    novamente
                </Typography>
            </Box>
        </POSLayout>
    )
}

export const POSErrorBoundary = (props: POSErrorBoundaryProps) => (
    <ErrorBoundary {...props} FallbackComponent={ErrorFallback} />
)
