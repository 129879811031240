import { Box, Typography } from '@material-ui/core'

import { testID } from 'src/config/test'

import { POSPriceWithInstallments } from 'src/components'

import { useStyles } from './style'
import { MainPrice } from './components/MainPrice'
import { Subtitle } from './components/Subtitle'
import { formatCurrency } from 'src/utils'
import { useContext } from 'react'
import { MainContext } from 'src/providers/Main'

export type PriceProps = {
    couponPercentage?: number
    price?: number
    installmentPrice: number
    installments?: number
    hasDiscount?: boolean
    priceWithCoupon?: number
    priceFirstInstallment?: number
    immediateStart?: boolean
    courseName?: string
}

export const Price = ({
    price = 0,
    installments = 1,
    couponPercentage = 0,
    installmentPrice,
    hasDiscount,
    priceWithCoupon = 0,
    priceFirstInstallment = 0,
    immediateStart,
}: PriceProps) => {
    const classes = useStyles({ hasCoupon: !!couponPercentage })

    const hasDiscountWithCoupon = !!couponPercentage && hasDiscount
    const hasCoupon = !!couponPercentage

    const {
        isRecurrenceEnabled,
        primaryProduct,
        isPromoEnabled,
        isBankSlipEnabled,
    } = useContext(MainContext)

    return (
        <Box
            width={{ xs: 'auto', md: '50%' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
        >
            <Box mb={2}>
                {hasCoupon ? (
                    <Typography
                        variant="caption"
                        style={{
                            fontWeight: 700,
                            color:
                                !isPromoEnabled && immediateStart
                                    ? '#FFF27C'
                                    : 'secondary',
                        }}
                        {...testID('has-coupon')}
                    >
                        Veja quanto você vai economizar:
                    </Typography>
                ) : (
                    <Typography
                        variant="caption"
                        className={
                            !isPromoEnabled && immediateStart
                                ? classes.immediate
                                : classes.primary
                        }
                        style={{
                            fontWeight: 500,
                        }}
                        {...testID('no-coupon')}
                    >
                        Investimento:
                    </Typography>
                )}
            </Box>

            {!isBankSlipEnabled && isPromoEnabled && !isRecurrenceEnabled ? (
                <Box display="flex" flexDirection="row">
                    {/* Start promo */}
                    <MainPrice className={classes.promoMainPrice}>
                        1ª parcela{' '}
                        <Typography
                            style={{
                                color: '#2CAA6E',
                                fontSize: '28px',
                                fontWeight: 700,
                            }}
                        >
                            <POSPriceWithInstallments
                                price={priceFirstInstallment}
                                installments={1}
                            />
                        </Typography>
                    </MainPrice>

                    {/* Total de parcelas - 1, com o desconto aplicado: + 14x de R$ 000,00 */}
                    <Box display="flex" flexDirection="row" gridGap={2}>
                        <Box
                            style={{
                                marginRight: '15px',
                                marginLeft: '15px',
                            }}
                        >
                            <Typography
                                style={{
                                    color: '#072869',
                                    fontSize: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                +{' '}
                            </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            {installments - 1 > 1 && (
                                <Typography
                                    style={{
                                        color: '#072869',
                                        fontSize: '18px',
                                        fontWeight: 700,
                                    }}
                                >
                                    {!primaryProduct?.isFirstChargeReductionCampaign
                                        ? installments - 1
                                        : installments}
                                    x de
                                </Typography>
                            )}
                            <Typography
                                style={{
                                    color: '#072869',
                                    fontSize: '28px',
                                    fontWeight: 700,
                                }}
                            >
                                {formatCurrency.format(installmentPrice)}*
                            </Typography>
                        </Box>
                    </Box>
                    {/* End promo */}
                </Box>
            ) : (
                <>
                    {/* Sem desconto, sem cupom */}
                    {!hasCoupon && !hasDiscount && (
                        <Box>
                            <MainPrice
                                className={
                                    !isPromoEnabled && immediateStart
                                        ? classes.immediate
                                        : classes.secondary
                                }
                            >
                                <POSPriceWithInstallments
                                    price={installmentPrice!}
                                    installments={installments}
                                />
                                *
                            </MainPrice>
                        </Box>
                    )}

                    {/* Com desconto e sem cupom */}
                    {hasDiscount && !hasCoupon && (
                        <Box>
                            <Subtitle
                                style={{
                                    color:
                                        !isPromoEnabled && immediateStart
                                            ? '#ffffff'
                                            : '#808080',
                                    textDecoration: 'line-through',
                                }}
                            >
                                <POSPriceWithInstallments
                                    price={price}
                                    installments={installments}
                                />
                            </Subtitle>
                            <MainPrice
                                className={
                                    !isPromoEnabled && immediateStart
                                        ? classes.immediate
                                        : classes.secondary
                                }
                                {...testID('has-discount')}
                            >
                                <POSPriceWithInstallments
                                    price={installmentPrice}
                                    installments={installments}
                                />
                                *
                            </MainPrice>
                        </Box>
                    )}

                    {/* Com cupom e sem desconto */}
                    {hasCoupon && !hasDiscount && (
                        <Box>
                            <Box display="flex" alignItems="center">
                                <Subtitle
                                    style={{
                                        color:
                                            !isPromoEnabled && immediateStart
                                                ? '#ffffff'
                                                : '#808080',
                                        textDecoration: 'line-through',
                                    }}
                                >
                                    <POSPriceWithInstallments
                                        price={price}
                                        installments={installments}
                                    />
                                    &nbsp;
                                </Subtitle>
                                <Typography
                                    variant="subtitle1"
                                    className={
                                        !isPromoEnabled && immediateStart
                                            ? classes.immediateSecondary
                                            : classes.secondary
                                    }
                                    {...testID('coupon-precentage')}
                                >
                                    {`${couponPercentage}%off`}
                                </Typography>
                            </Box>
                            <MainPrice
                                className={
                                    !isPromoEnabled && immediateStart
                                        ? classes.immediateSecondary
                                        : classes.secondary
                                }
                            >
                                <POSPriceWithInstallments
                                    price={priceWithCoupon}
                                    installments={installments}
                                />
                                *
                            </MainPrice>
                        </Box>
                    )}
                </>
            )}

            {/* Com cupom e com desconto */}
            {hasDiscountWithCoupon && (
                <Box>
                    <Box display="flex" alignItems="center">
                        <Subtitle
                            style={{
                                color:
                                    !isPromoEnabled && immediateStart
                                        ? '#ffffff'
                                        : 'secondary',
                                textDecoration: 'line-through',
                            }}
                        >
                            <POSPriceWithInstallments
                                price={price}
                                installments={installments}
                            />
                        </Subtitle>
                        <Typography
                            variant="subtitle1"
                            className={
                                !isPromoEnabled && immediateStart
                                    ? classes.immediateSecondary
                                    : classes.secondary
                            }
                            {...testID('coupon-precentage')}
                        >
                            &nbsp;{`${couponPercentage}%off`}
                        </Typography>
                    </Box>
                    <Subtitle
                        className={
                            !isPromoEnabled && immediateStart
                                ? classes.immediateSecondary
                                : classes.secondary
                        }
                        style={{ textDecoration: 'line-through' }}
                        {...testID('price-with-coupon')}
                    >
                        <POSPriceWithInstallments
                            price={priceWithCoupon}
                            installments={installments}
                        />
                        *
                    </Subtitle>
                    <MainPrice
                        className={
                            !isPromoEnabled && immediateStart
                                ? classes.immediateSecondary
                                : classes.secondary
                        }
                    >
                        <POSPriceWithInstallments
                            price={installmentPrice}
                            installments={installments}
                        />
                        *
                    </MainPrice>
                </Box>
            )}

            {isRecurrenceEnabled ? (
                <Box mb={1} maxWidth={280}>
                    <Box mb={1}>
                        <Typography
                            className={
                                immediateStart && !isPromoEnabled
                                    ? classes.immediate
                                    : classes.primary
                            }
                            variant="caption"
                            style={{ fontWeight: 700, lineHeight: '14px' }}
                        >
                            {`* Comprometa ${formatCurrency.format(
                                primaryProduct?.cycleTotalPrice!
                            )} do seu limite ${
                                primaryProduct?.numberOfCyles &&
                                primaryProduct.numberOfCyles > 1
                                    ? `a cada ${primaryProduct?.cycleInMonths} meses`
                                    : ''
                            }`}
                        </Typography>
                    </Box>

                    <Box mb={1}>
                        <Typography
                            className={
                                immediateStart && !isPromoEnabled
                                    ? classes.immediate
                                    : classes.primary
                            }
                            variant="caption"
                            style={{ fontWeight: 700, lineHeight: '14px' }}
                        >
                            Simule outras possibilidades de pagamentos e
                            parcelas nos próximos passos
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Box mb={1} maxWidth={280}>
                    <Box mb={1}>
                        <Typography
                            className={
                                !isPromoEnabled && immediateStart
                                    ? classes.immediate
                                    : classes.primary
                            }
                            variant="caption"
                            style={{ fontWeight: 700, lineHeight: '14px' }}
                        >
                            *Parcelamento via Cartão de Crédito que não
                            compromete seu limite.
                        </Typography>
                    </Box>

                    <Box mb={1} />
                    {(isPromoEnabled || isBankSlipEnabled) && (
                        <Typography
                            variant="caption"
                            style={{
                                lineHeight: '14px',
                                fontSize: '0.625rem',
                                color: isPromoEnabled
                                    ? '#808080'
                                    : immediateStart
                                    ? '#E0EBFF'
                                    : 'textSecondary',
                            }}
                        >
                            **Parcelamos também via boleto, com alteração de
                            valor.
                        </Typography>
                    )}
                </Box>
            )}
        </Box>
    )
}
