import { useCallback } from 'react'

import { useMediaQuery, useTheme } from '@material-ui/core'

export const useScrollToElement = (query?: string) => {
    const theme = useTheme()
    const matches = useMediaQuery(query || theme.breakpoints.down('md'))

    const goScrollTo = useCallback(
        (idElement: string) => {
            if (matches) {
                document.getElementById(idElement)?.scrollIntoView()
            }
        },
        [matches]
    )

    return { goScrollTo }
}
