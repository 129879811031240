import React from 'react'

import { POSErrorBoundary } from 'src/components'

import { useTrackerContext } from 'src/hooks'

export const ErrorBoundary = ({ children }: React.PropsWithChildren<{}>) => {
    const { track } = useTrackerContext()

    const handleTrackError = (error: Error) => {
        track({
            eventName: 'Error loaded',
            additionalAttrs: {
                'Internal error': error?.message,
            },
        })
    }

    return (
        <POSErrorBoundary onError={handleTrackError}>
            {children}
        </POSErrorBoundary>
    )
}
