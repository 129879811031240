import { useQuery, UseQueryOptions } from 'react-query'

import { posCheckoutBffClient } from 'src/config/data-access/axios'

import { ICourse } from 'src/types'

type GetProductsParams = {
    slug: string
    coupon?: string
    recurrence?: boolean
    boleto?: boolean
}

export const useGetProducts = (
    { slug, coupon, recurrence, boleto }: GetProductsParams,
    options?: UseQueryOptions<ICourse, any>
) => {
    const promoCode = !!coupon ? `&promoCode=${coupon}` : ''
    const instance = posCheckoutBffClient
    const recurrenceParam = recurrence ? '&recurrence=true' : ''
    const bankSlipParam = boleto ? '&bankSlip=true' : ''
    const path = `v1/class?slug=${slug}${promoCode}${recurrenceParam}${bankSlipParam}`

    return useQuery<ICourse>(
        path,
        async () => {
            return await instance.get(path).then(({ data }) => data)
        },
        options
    )
}
