import { Link, Box, Typography } from '@material-ui/core'

const fontWeight = 500

const commonProps = {
    style: { fontWeight },
    underline: 'always',
    color: 'inherit',
    target: '_blank',
    rel: 'noreferrer',
} as any

export const About = () => {
    return (
        <Box>
            <Typography paragraph style={{ fontWeight: 700 }}>
                Sobre
            </Typography>
            <Box>
                <Box mb={1}>
                    <Link {...commonProps} href="https://books.sanarmed.com/">
                        E-books
                    </Link>
                </Box>
                <Box mb={1}>
                    <Link
                        {...commonProps}
                        href="https://www.sanarmed.com/tags/pos-graduacao"
                    >
                        Blog
                    </Link>
                </Box>
                <Box mb={1}>
                    <Link
                        {...commonProps}
                        href="https://www.youtube.com/playlist?list=PLa5baA3XxE2-ScySnvZVagSIRfLPw7ggY"
                    >
                        Aulas Ao Vivo
                    </Link>
                </Box>
            </Box>
        </Box>
    )
}
