import { useMutation, UseMutationOptions } from 'react-query'
import { posCheckoutBffClient } from 'src/config/data-access/axios'

import { PaymentMethodType } from 'src/types'

interface IAddress {
    zipCode: string
    street: string
    number: string
    neighborhood: string
    city: string
    state: string
    country: string
    complement?: string
}

interface IPhone {
    number: string
    countryCode: string
}

interface IUser {
    name: string
    email: string
    cpf: string
    phone: IPhone
    address: IAddress
    rg: string
    birthdate: Date
    gender: string
}

export interface IDiscount {
    type: DiscountType
    category: DiscountCategory
    value: number
    numberOfCycles: number | null
}

export enum DiscountType {
    PERCENTAGE = 'percentage',
}

export enum DiscountCategory {
    PLAN = 'plan',
}

export interface IResult {
    id: number
    bankSlipUrl: string
    barcode: string
    typableBarcode: string
}

interface IPurchase {
    user: IUser
    paymentMethod: PaymentMethodType
    paymentToken: string
    planSlug: string
    productSlug: string
    acceptTerms: boolean
    promoCode?: string
    contractId: number
    billingDay?: number
}

export const useCreatePurchaseV2 = (
    options?: UseMutationOptions<IResult, any, IPurchase>
) => {
    return useMutation<IResult, any, IPurchase>((purchase) => {
        return posCheckoutBffClient
            .post('v1/purchase', purchase)
            .then(({ data }) => data)
    }, options)
}
