import React from 'react'
import { Box, Button, Modal, Paper, Typography } from '@material-ui/core'

import { Close } from '@material-ui/icons'
import { ReactComponent as CreditCardIcon } from 'src/assets/images/credit-card.svg'
import { ReactComponent as BankSlipIcon } from 'src/assets/images/bank-slip.svg'

import { formatCurrency } from 'src/utils'
import { ICourseData, IProductData } from 'src/providers/Main'

interface IPosModalProps {
    open: boolean
    onClose: () => void
    isMobile: boolean
    hasBankSlip: boolean
    isPromoEnabled: boolean
    isRecurrenceEnabled: boolean
    data: ICourseData
}

interface PriceDetailsProps {
    products: IProductData[]
    isPromoEnabled: boolean
    hasBankSlip: boolean
}

interface IPriceTitleProps {
    children: React.ReactNode
}

interface IModalTitleProps {
    children: React.ReactNode
    title: string
}

const ModalTitle = ({ children, title }: IModalTitleProps) => {
    return (
        <Typography
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
            }}
        >
            {children}
            {title}
        </Typography>
    )
}

const PriceTitle = ({ children }: IPriceTitleProps) => {
    return (
        <Typography style={{ fontWeight: 700, marginBottom: 12 }}>
            {children}
        </Typography>
    )
}

const PriceDetails = ({
    products,
    isPromoEnabled,
    hasBankSlip,
}: PriceDetailsProps) => {
    const hasDiscounts = Boolean(
        products.find((product) => product.discountsPercentageTotal > 0)
    )

    return (
        <Box
            display="flex"
            style={{
                gap: 16,
            }}
            marginTop={4}
        >
            <Box>
                <PriceTitle>Condição</PriceTitle>
                {products &&
                    products.map((item, index) => {
                        return (
                            <Typography
                                style={{ fontWeight: 700, marginBottom: 20 }}
                                color="secondary"
                                key={`${item.id}-${index}`}
                            >
                                {!hasBankSlip &&
                                isPromoEnabled &&
                                item.installments > 1
                                    ? item.installments - 1
                                    : item.installments}
                                x de{' '}
                                {formatCurrency.format(item.installmentPrice)}
                            </Typography>
                        )
                    })}
            </Box>
            <Box>
                <PriceTitle>Valor Total</PriceTitle>
                {products &&
                    products.map((item, index) => {
                        return (
                            <Typography
                                style={{ marginBottom: 20 }}
                                key={`${item.id}-${index}`}
                            >
                                {formatCurrency.format(item.totalValueToPay)}
                            </Typography>
                        )
                    })}
            </Box>
            {hasDiscounts && (
                <Box>
                    <PriceTitle>Desc.</PriceTitle>
                    {products &&
                        products.map((item, index) => {
                            let discountPercentage

                            if (item.discountsPercentageTotal > 0) {
                                discountPercentage =
                                    item.discountsPercentageTotal
                            }

                            return (
                                <Typography
                                    style={{
                                        fontWeight: 700,
                                        color: '#2CAA6E',
                                        marginBottom: 20,
                                    }}
                                    key={`${item.description}-${index}`}
                                >
                                    {discountPercentage
                                        ? `(${discountPercentage}%)`
                                        : '--'}
                                </Typography>
                            )
                        })}
                </Box>
            )}
        </Box>
    )
}

const renderBankSlipHeader = () => (
    <ModalTitle title="Boleto">
        <BankSlipIcon />
    </ModalTitle>
)

const renderPromoHeader = (firstInstallmentPrice: number) => {
    return (
        <Box
            height="42px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            bgcolor="#010E2C"
            style={{ borderRadius: '8px 8px 0px 0px' }}
            marginTop="26px"
        >
            <Typography
                style={{
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: 700,
                }}
            >
                {`1ª parcela de ${formatCurrency.format(
                    firstInstallmentPrice
                )}**`}
            </Typography>
            &nbsp;
            <Typography
                style={{
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: 700,
                }}
            >
                +
            </Typography>
        </Box>
    )
}

const renderPromoMessage = () => (
    <Typography
        color="primary"
        variant="body2"
        style={{
            fontWeight: 500,
            paddingBottom: 16,
        }}
    >
        **Não válida para pagamentos à vista
    </Typography>
)

export const PosModal = ({
    open,
    onClose,
    isMobile,
    hasBankSlip,
    isPromoEnabled,
    isRecurrenceEnabled,
    data,
}: IPosModalProps) => {
    const filterByPaymentMethod = (paymentMethod: string) => {
        return (
            data &&
            data.products
                .map((product) => product)
                .filter((price) => price.paymentMethod === paymentMethod)
        )
    }

    const creditCard = filterByPaymentMethod('credit_card')
    const bankSlip = filterByPaymentMethod('bank_slip')

    const firstInstallmentPrice = creditCard.find(
        (item) => item.priceFirstInstallment! > 0
    )

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'grid',
                placeItems: 'center',
            }}
        >
            <Paper
                style={{
                    padding: 16,
                    position: 'relative',
                }}
                elevation={10}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    marginTop="10px"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        {!hasBankSlip && (
                            <ModalTitle title="Cartão de Crédito*">
                                <CreditCardIcon color="#65686A" />
                            </ModalTitle>
                        )}

                        {hasBankSlip && !isMobile && renderBankSlipHeader()}

                        <Close onClick={onClose} color="action" />
                    </Box>

                    {!hasBankSlip &&
                        isPromoEnabled &&
                        !isRecurrenceEnabled &&
                        renderPromoHeader(
                            firstInstallmentPrice?.priceFirstInstallment || 0
                        )}

                    <Box
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                    >
                        {!hasBankSlip && (
                            <Box>
                                <PriceDetails
                                    products={creditCard}
                                    isPromoEnabled={isPromoEnabled}
                                    hasBankSlip={hasBankSlip}
                                />

                                <Typography
                                    color="primary"
                                    variant="body2"
                                    style={{
                                        fontWeight: 500,
                                        paddingBottom:
                                            isMobile && hasBankSlip ? 0 : 16,
                                    }}
                                >
                                    *Parcelamento via cartão de crédito que{' '}
                                    <br /> não compromete seu limite.
                                </Typography>

                                {isPromoEnabled && renderPromoMessage()}
                            </Box>
                        )}

                        {hasBankSlip && isMobile && renderBankSlipHeader()}

                        {hasBankSlip && (
                            <Box paddingBottom={'20px'}>
                                <PriceDetails
                                    products={bankSlip}
                                    isPromoEnabled={isPromoEnabled}
                                    hasBankSlip={hasBankSlip}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box display="flex" justifyContent="center">
                    <Button
                        onClick={onClose}
                        variant="contained"
                        color="primary"
                        style={{
                            fontWeight: 700,
                            width: 320,
                            height: 46,
                            padding: 8,
                        }}
                    >
                        Fechar
                    </Button>
                </Box>
            </Paper>
        </Modal>
    )
}
